import React, { useState } from "react";
import signin from "../../assets/images/loginImage.png";
import authService from "../../services/Auth/login.service";
import localStorage from "../../helpers/token.helpers";
import { useNavigate } from "react-router-dom";
import loader from "../../assets/images/loader.gif";

const Signin = () => {
  const [loading, setLoading] = useState(false);

  const [formdata, setFormdata] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required.";
    }
    if (!values.password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      localStorage.removeSiteId();
      const formErrors = validate(formdata);
      if (Object.keys(formErrors).length === 0) {
        const response = await authService.signin(formdata);
        if (response) {
          localStorage.setToken(response.accessToken);
          localStorage.setUser(JSON.stringify(response));
          navigate("/app/dashboard");
        }
      } else {
        setErrors(formErrors);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-row vh-100">
        <div className="signin-form-container col-md-4 col-lg-4 col-xl-4 d-flex align-items-center justify-content-center">
          <div className="signin-form-wrapper w-100 p-2">
            {loading && (
              <img src={loader} width="50%" className="signin-loader" />
            )}
            <div>
              <h4>SIGN IN</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="signin-form-group pt-4">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control shadow-none signin-input"
                  name="username"
                  onChange={handleOnChange}
                />
                <p className="text-danger signin-error">{errors.username}</p>
              </div>
              <div className="signin-form-group pt-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control shadow-none signin-input"
                  name="password"
                  onChange={handleOnChange}
                />
                <p className="text-danger signin-error">{errors.password}</p>
              </div>
              <div className="signin-form-group pt-3">
                <input
                  type="submit"
                  className="btn-custom w-100"
                  value="Sign In"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="signin-image-container col-md-8 col-lg-8 col-xl-8 d-flex align-items-center justify-content-center">
          <img
            src={signin}
            alt="Sign In"
            className="img-fluid rounded signin-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Signin;
