import { useAtom } from "jotai";
import {
  siteName,
  atomSum,
  atomUtility,
  unitDifference,
} from "../../global/atom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faArrowTrendDown,
} from "@fortawesome/free-solid-svg-icons";
const RightPanel = () => {
  const [data, setData] = useAtom(siteName);
  const [sum, setSum] = useAtom(atomSum);
  const [utility, setUtility] = useAtom(atomUtility);
  const [unitdifference, setunitDifference] = useAtom(unitDifference);
  return (
    <>
      <div className="bg-grey fixed-right">
        <div className="p-2">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 font-orange">
              <b>{data}</b>
            </div>
            <div className="py-4 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 py-1">
              <small>Total Consumption Energy</small>
            </div>
            <div
              className="py-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-1"
              style={{ color: unitdifference > 0 ? "green" : "red" }}
            >
              {unitdifference < 0 ? (
                <FontAwesomeIcon icon={faArrowTrendDown} />
              ) : (
                <FontAwesomeIcon icon={faArrowTrendUp} />
              )}{" "}
              {unitdifference > 0 ? unitdifference : unitdifference * -1}
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-1">
              <b>{sum} kwh</b>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-1 d-flex justify-content-end currency-symbol">
              <b>{sum * utility}</b>
            </div>
          </div>

          <div className="row p-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center"></div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RightPanel;
