import api from "../../services/interceptors/axios.interceptor";

const getMachine = async (clientID) => {
  try {
    const response = await api.get("machines/client/" + clientID);
    // console.log(response,'response');
    return response;
  } catch (err) {
    throw err;
  }
};

const getMachinebySite = async (clientID, siteID) => {
  try {
    const response = await api.get(
      `machines/site/?clientid=${clientID}&siteid=${siteID}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const getMachinebyuuid = async (uuid) => {
  try {
    const response = await api.get(`machines/${uuid}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const getRealtimesummary = async (id) => {
  try {
    const response = await api.get("reports/realtimesummary/" + id);
    return response;
  } catch (err) {
    throw err;
  }
};

const getmachinesummary = async (id) => {
  try {
    const response = await api.get("reports/machinesummary/" + id);
    return response;
  } catch (err) {
    throw err;
  }
};

const getmachinedata = async (id, fromdate, todate, type) => {
  try {
    const response = await api.get(
      `reports/machine/${id}?fromdate=${fromdate}&todate=${todate}&type=${type}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export default {
  getMachine,
  getRealtimesummary,
  getMachinebySite,
  getMachinebyuuid,
  getmachinesummary,
  getmachinedata,
};
