import api from "../../services/interceptors/axios.interceptor";

const signin = async (payload) => {
  try {
    if (payload) {
      const response = await api.post("auth/signin", payload);
      return response;
    }
  } catch (err) {
    throw err;
  }
};

export default { signin };
