import React, { useEffect, useState } from "react";
import reportImage from "../../assets/images/report.png";
import reportService from "../../services/Report/report.service";
import tokenHelpers from "../../helpers/token.helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import Loader from "../loader/Loader";
const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [sections, setSections] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [formdata, setFormdata] = useState({
    site: "",
    section: "",
    machine: "",
    date: "",
  });

  const handleOnchange = async (e) => {
    try {
      const { name, value } = e.target;
      const updatedFormdata = {
        ...formdata,
        [name]: value,
      };
      setFormdata(updatedFormdata);

      if (updatedFormdata.machine !== "") {
        const machineData = machines.filter(
          (item) => item.id == updatedFormdata.machine
        );

        setMachine(machineData);
        // console.log(machineData, "machine");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnLoad = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(tokenHelpers.getUser());
      const response = await reportService.getFloor(user.clientId);
      setSites(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleUtilities = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(tokenHelpers.getUser());
      const response = await reportService.utilities(user.clientId);
      setUtilities(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleSection = async (e) => {
    try {
      const site = sites.find((site) => site.id == e.target.value);
      setSections(site.sections);
    } catch (err) {
      console.error(err);
    }
  };

  const handleMachine = async (e) => {
    try {
      const site = sites.find((site) =>
        site.sections.some((section) => section.id == e.target.value)
      );

      if (site) {
        const section = site.sections.find(
          (section) => section.id == e.target.value
        );
        setMachines(section.machines);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getReport = async (type, format) => {
    setLoading(true);
    const site = formdata.site;
    const section = formdata.section;
    const machine = formdata.machine;
    const rtype = type;
    const rformat = format;
    const amount = 10;
    const date = formdata.date;
    if (site && section && machine && date) {
      const response = await reportService.getReport(
        site,
        section,
        machine,
        rtype,
        rformat,
        amount,
        date
      );

      if (response) {
        const formats = format === "pdf" ? ".pdf" : ".xlsx";
        let filename;

        if (machine !== undefined) {
          filename = `${type}_${site}_${section}_${machine}${formats}`;
        } else if (section !== undefined) {
          filename = `${type}_${site}_${section?.id}${formats}`;
        } else {
          filename = `${type}_${site}${formats}`;
        }

        try {
          //   setLoading(true);
          const downloadResp = await reportService.download(filename);

          setTimeout(() => {
            // setLoading(false);
            const link = document.createElement("a");
            link.setAttribute("type", "hidden");
            link.href = `${process.env.REACT_APP_REPORT_URL}export/${filename}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
          }, 2000);
        } catch (err) {
          //   setLoading(false);
          alert(`Error while downloading file: ${err.message}`);
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    handleOnLoad();
    handleUtilities();
  }, []);

  return (
    <>
      <div className="">
        {loading && <Loader />}
        <div className="row">
          {/* Left column for the image */}
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
            <div className="row mt-3">
              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <select
                  className="form-control-sm shadow-none w-100"
                  name="site"
                  value={formdata.site}
                  onChange={(e) => {
                    handleSection(e);
                    handleOnchange(e);
                  }}
                >
                  <option value="">Select Site</option>
                  {sites.map((site, index) => (
                    <option key={index} value={site.id}>
                      {site.site_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <select
                  className="form-control-sm shadow-none w-100"
                  name="section"
                  value={formdata.section}
                  onChange={(e) => {
                    handleMachine(e);
                    handleOnchange(e);
                  }}
                >
                  <option value="">Select Section</option>
                  {sections.map((section, index) => (
                    <option key={index} value={section.id}>
                      {section.section}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <select
                  className="form-control-sm shadow-none w-100"
                  name="machine"
                  value={formdata.machine}
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                >
                  <option value="">Select Machine</option>
                  {machines.map((machine, index) => (
                    <option key={index} value={machine.id}>
                      {machine.machine_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <input
                  type="date"
                  className="form-control-sm w-100"
                  name="date"
                  value={formdata.date}
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <table className="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Calendar</th>
                      <th>Pdf Download</th>
                      <th>Excel Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Add table rows here */}
                    <tr>
                      <td>Today</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("today", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("today", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>Yesterday</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("yesterday", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("yesterday", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>This Week</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("weekly", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("weekly", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>Last Week</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastweek", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastweek", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>This Month</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("monthly", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("monthly", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>Last Month</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastmonth", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastmonth", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>This Year</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("yearly", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("yearly", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>Last Year</td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastyear", "pdf");
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} /> Download
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn-custom-green"
                          onClick={() => {
                            getReport("lastyear", "excel");
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> Download
                        </button>
                      </td>
                    </tr>
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Right column for other content */}
          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            {/* First section */}
            <div className="bg-grey fixed-right">
              <div className="p-2">
                <div className="row">
                  <div className="col-12">
                    {machine.length > 0 && (
                      <div>
                        <div className="font-orange text-center p-1">
                          Device Details
                        </div>
                        <div className="row">
                          <div className="col-8 ">
                            {machine.length > 0 && machine[0].machine_name}
                          </div>
                          <div className="col-4  d-flex justify-content-end">
                            {machine.length > 0 && machine[0].macid}
                          </div>
                        </div>
                        <hr />
                      </div>
                      
                    )}
                    <div className="p-2">
                      
                    </div>
                    <div className="font-orange text-center font-robot-21">Preview</div>
                    <div className="py-3">
                      <img src={reportImage} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Your content here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
