import React from "react";
import chartData from "../helpers/chartData";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const LineChart = ({ dataLable, dataSeries, order, Chartfill=false }) => {
  var reversedDataLable;
  var reversedDataSeries;
  var formattedDataLable;

  if (dataLable) {
    formattedDataLable = dataLable.map(chartData.formatDate);
    if (order) {
      reversedDataLable = [...formattedDataLable].reverse();
      reversedDataSeries = [...dataSeries].reverse();
    } else {
      reversedDataLable = [...formattedDataLable];
      reversedDataSeries = [...dataSeries];
    }
  }

  const data = {
    labels: reversedDataLable,
    datasets: [
      {
        label: "Kwh",
        data: reversedDataSeries,
        fill: Chartfill,
        borderColor: "#000000",
        borderWidth: 1,
        tension: 0.1,
        pointBackgroundColor: "#179A00",
        pointBorderColor: "#179A00",
        pointHoverBackgroundColor: "#179A00",
        pointHoverBorderColor: "#179A00",
        pointRadius: 3.5,
        pointStyle: "circle",
        borderDash: [5, 5],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            return ` ${tooltipItem.raw}`; // Display value on hover
          },
        },
      },
      datalabels: {
        display: false, // Hide data labels by default
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: "Month",
        },
        ticks: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: false,
          text: "Value",
        },
        ticks: {
          display: true,
        },
      },
    },
    hover: {
      onHover: function (event, chartElement) {
        if (chartElement.length) {
          const index = chartElement[0].index;
          // Dynamically update tooltip visibility on hover
          options.plugins.tooltip.callbacks.label = function (tooltipItem) {
            return ` ${reversedDataSeries[index]}`; // Display value on hover
          };
        }
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
