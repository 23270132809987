import api from "../interceptors/axios.interceptor";

const getSitebyUUID = async (uuid) => {
  try {
    const response = await api.get(`sites/${uuid}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export default { getSitebyUUID };
