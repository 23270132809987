import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
const ControllerCard = ({ controller, handlePowerToggle, togglePopup }) => {
  // const [isOn, setIsOn] = useState(false);

  return (
    <>
      <div
        class="card"
        className="bgColor"
        style={{ borderRadius: "5px", cursor: "pointer" }}
      >
        <div class="card-body">
          <p class="card-text rounded">
            <div className="row p-2">
              <div className="col-6">
                <FontAwesomeIcon className="font-green" icon={faBolt} />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div class="form-check form-switch">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={controller.power}
                    onChange={(e) => {
                      handlePowerToggle(controller.macid, controller.power);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-12">
                <b>{controller?.controller_name}</b>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <span className="">{controller?.site?.site_name}</span>
                  </div>
                  <div className="col-6 d-flex justify-content-end font-orange">
                    <button onClick={togglePopup} className="btn-custom">schedule</button>
                  </div>
                </div>
              </div>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default ControllerCard;
