import React, { useEffect, useState } from "react";
import Card from "../../views/Cards";
import tokenHelpers from "../../helpers/token.helpers";
import Loader from "../loader/Loader";
import { useAtom } from "jotai";
import { siteList } from "../../global/atom";
import reportService from "../../services/Report/report.service";
import DummyLineChart from "../../views/DummyLineChart";
import { useTheme } from "../Theme/ThemeContext";

const MachineDashboard = () => {
  const { darkTheme } = useTheme();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(tokenHelpers.getUser());
  const [machines, setMachines] = useState([]);
  const [todayData, setTodayData] = useState({
    today: {},
    yesterday: {},
    week: {},
    lastweek: {},
    month: {},
    lastmonth: {},
    chart: {},
  });
  const [chartData, setChartData] = useState({});
  const [siteLists, setSiteLists] = useAtom(siteList);
  const [site, setSite] = useState({});
  const [pichartData, setPichartData] = useState([]);

  const generateRandomNumbers = (min, max, count) => {
    const randomNumbers = [];
    for (let i = 0; i < count; i++) {
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  };

  const getTodayData = async (siteId) => {
    try {
      const response = await reportService.getToday(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getYesterdayData = async (siteId) => {
    try {
      const response = await reportService.getYesterday(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getWeek = async (siteId) => {
    try {
      const response = await reportService.getWeek(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getLastWeek = async (siteId) => {
    try {
      const response = await reportService.getLastWeek(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getMonth = async (siteId) => {
    try {
      const response = await reportService.getMonth(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getLastMonth = async (siteId) => {
    try {
      const response = await reportService.getLastMonth(siteId);
      return parseInt(response.totalunits) || 0;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getChartData = async (siteId) => {
    try {
      const response = await reportService.sectionData(siteId);
      return response;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  // useEffect(() => {
  const fetchTodayData = async (siteid) => {
    setLoading(true);
    const data = {
      today: {},
      yesterday: {},
      week: {},
      lastweek: {},
      month: {},
      lastmonth: {},
    };
    // for (const site of siteLists) {
    data.today[siteid] = await getTodayData(siteid);
    data.yesterday[siteid] = await getYesterdayData(siteid);
    data.week[siteid] = await getWeek(siteid);
    data.lastweek[siteid] = await getLastWeek(siteid);
    data.month[siteid] = await getMonth(siteid);
    data.lastmonth[siteid] = await getLastMonth(siteid);
    // }
    setTodayData(data);
    setLoading(false);
  };

  //   fetchTodayData();
  // }, [siteLists]);

  // useEffect(() => {
    const fetchChartData = async () => {
      setLoading(true);
      const data = {};
      for (const site of siteLists) {
        data[site.id] = await getChartData(site.id);
      }
      setChartData(data);
      setLoading(false);
      // console.log(chartData, "chartData");
    };

    
  // }, [todayData]);

  useEffect(() => {
    let linechartdata = [];
    let overallsectionTotal = 0;
    let sitedatas = { totalunits: 0 };
    Object.keys(chartData).forEach((key, value) => {
      const siteID = key;
      Object.keys(chartData[key]["sections"]).forEach(
        (machinekey, machinevalues) => {
          if (chartData[key]["sections"][machinekey].machines != undefined) {
            var sectotal = 0;
            Object.keys(
              chartData[key]["sections"][machinekey].machines
            ).forEach((mkey, mvalue) => {
              sectotal +=
                chartData[key]["sections"][machinekey].machines[mkey]
                  .totalunits;
            });
            chartData[key]["sections"].sectotal = sectotal;
            overallsectionTotal += sectotal;
            linechartdata.push({
              x: chartData[key]["sections"][machinekey].name,
              y: parseInt(sectotal),
              siteid: siteID,
            });
          }
        }
      );
    });
    setPichartData(linechartdata);

    // console.log(linechartdata, " linechartdatas");
  }, [chartData]);

  const formatFunction = (type, siteId) => {
    try {
      let returnData;
      if (pichartData.length > 0) {
        if (type == "x") {
          const item = pichartData
            .filter((item) => item.siteid == siteId)
            .map((item) => item.x);

          return item;
        } else {
          const item = pichartData
            .filter((item) => item.siteid == siteId)
            .map((item) => item.y);

          return item;
        }
        // console.log(returnData, "returnData");
        return returnData;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDropdown = (e) => {
    const siteid = e.target.value;
    const selectedSite = siteLists.filter((item) => item.id == siteid);
    if (selectedSite.length > 0) {
      setSite(selectedSite[0]);
      fetchTodayData(selectedSite[0].id);
      fetchChartData();
    }
  };

  return (
    <>
      <div className="">
        {loading && <Loader />}
        <div className="row">
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9 bgColor">
            <div className="p-2">
              <select
                className="form-control shadow-none w-50"
                onChange={(e) => {
                  handleDropdown(e);
                }}
              >
                <option value="">Select Site</option>
                {siteLists.length > 0 &&
                  siteLists.map((site) => (
                    <option value={site.id}>{site.site_name}</option>
                  ))}
              </select>
            </div>
            {/* {siteLists.length > 0 &&
              siteLists.map((site) => ( */}
            <div className="row mb-4" key={site.id}>
              {/* <div className="mb-2">
                    <b>
                      {site.site_name} - {site.location}
                    </b>
                  </div> */}
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="Today"
                  units={todayData.today[site.id] || 0}
                  amount={todayData.today[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="Yesterday"
                  units={todayData.yesterday[site.id] || 0}
                  amount={todayData.yesterday[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="This Week"
                  units={todayData.week[site.id] || 0}
                  amount={todayData.week[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="Last Week"
                  units={todayData.lastweek[site.id] || 0}
                  amount={todayData.lastweek[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="This Month"
                  units={todayData.month[site.id] || 0}
                  amount={todayData.month[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <Card
                  title="Last Month"
                  units={todayData.lastmonth[site.id] || 0}
                  amount={todayData.lastmonth[site.id] * 10}
                  bgcolor="bg-grey"
                />
              </div>

              <div className="w-100 d-flex justify-content-center">
                {Object.keys(todayData.lastmonth).length > 0 && (
                  <DummyLineChart
                    dataLable={formatFunction("x", site.id)}
                    dataSeries={formatFunction("y", site.id)}
                  />
                )}
              </div>
            </div>
            {/* ))} */}
          </div>
          {/* <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div
              className={` ${darkTheme ? "fixed-right" : "fixed-right-lite"}`}
            >
              <div className="p-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 px-3 font-robot-21">
                    Site Analysis
                  </div>
                </div>
                <hr />
                {siteLists.length > 0 &&
                  siteLists.map((item) => (
                    <div className="row">
                      <div
                        className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 font-orange px-3 font-robot-17"
                        style={{ fontWeight: "bold" }}
                      >
                        {item.site_name}
                      </div>
                      <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 py-3 px-3">
                        <div className="font-robot-13">
                          Total Consumption Energy
                        </div>
                      </div>

                      <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 py-3 px-3">
                        <b>{todayData.today[item.id]} kwh</b>
                      </div>
                      <hr />
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MachineDashboard;
