import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSearch,
  faBell,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import HeaderService from "../../services/header/header.service";
import tokenHelpers from "../../helpers/token.helpers";
import clientService from "../../services/client/client.service";
import { useAtom } from "jotai";
import { siteList } from "../../global/atom";
import { useTheme } from "../Theme/ThemeContext";

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(tokenHelpers.getUser());
  const [siteLists, setSiteLists] = useAtom(siteList);
  const { darkTheme, setDarkTheme, energyIndex, setEnergyIndex } = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [sites, setSites] = useState([]); // List of sites
  const [site, setSite] = useState(""); // Selected site
  const [search, setSearch] = useState(false); // Selected site
  const [client, setClient] = useState({});

  useEffect(() => {
    handleOnload();
    handleDropdown();
  }, []);

  const handleOnload = async () => {
    try {
      const response = await clientService.getClienteatils(user.clientId);
      setClient(response);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDropdown = async () => {
    try {
      const user = JSON.parse(tokenHelpers.getUser());
      const response = await HeaderService.getSiteList(user.clientId);
      setSites(response);
      setSiteLists(response);
      const siteID = tokenHelpers.getSiteId();
      if (siteID) {
        setSite(siteID);
      }
    } catch (error) {
      console.error("Error fetching site list:", error);
      //   notificationRef.current.showNotification("danger", error.message);
    }
  };

  const handleOnChange = (e) => {
    if (e.target.value) {
      setSite(e.target.value);
      tokenHelpers.setSiteId(e.target.value);
      const selectedSite = sites.find((site) => site.id == e.target.value);
      if (selectedSite) {
        tokenHelpers.setSiteuuid(selectedSite.uuid);
      }
      // navigate(`/app/site/${e.target.value}`);
    }
  };

  const handleTheme = () => {
    setDarkTheme((prevTheme) => !prevTheme);
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleEnergyIndex = () => {
    setEnergyIndex(!energyIndex);
  };

  const handleLogout = () => {
    tokenHelpers.logout();
    navigate("/");
  };

  return (
    <div className={`w-100 ${darkTheme ? "" : ""} col-12 py-1 px-3`}>
      <div className="p-1">
        <div className="row">
          <div className="col-12">
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div>
                <label>
                  <b>{client?.client_name}</b>
                </label>
              </div>
              <div className="d-flex align-items-center">
                {search && (
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Search..."
                  />
                )}

                <span className="px-3 p-2">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      cursor: "pointer",
                      color: darkTheme ? "" : "#171C97",
                    }}
                    onClick={handleSearch}
                  />
                </span>

                <span className="px-3 p-2">
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{ cursor: "pointer" }}
                  />
                </span>

                <span className="px-3 p-2">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ cursor: "pointer" }}
                    onClick={handleEnergyIndex}
                  />
                </span>

                <span className="px-3 p-2">
                  <div className="dropdown">
                    <FontAwesomeIcon
                      icon={faUser}
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                  </div>
                </span>
                {user?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
