import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const DummyPieChart = ({ dataLable, dataSeries }) => {
  // console.log(dataLable,"dataLable");
  
  const data = {
    labels: dataLable,
    datasets: [
      {
        label: "Kwh",
        data: dataSeries,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Show legend
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataIndex = tooltipItem.dataIndex;
            const value = dataSeries[dataIndex];
            return ` ${dataLable[dataIndex]}: ${value}`; // Display label and value on hover
          },
        },
      },
    },
  };

  const chartStyle = {
    width: "550px",  // Adjust the width
    height: "550px", // Adjust the height
  };

  return <div style={chartStyle}><Pie data={data} options={options} /></div>;
};

export default DummyPieChart;
