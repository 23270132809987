import React, { useEffect, useState } from "react";
import moment from "moment";
import AddMaintenance from "./AddMaintenance";
import maintenanceService from "../services/maintenance/maintenance.service";
import CalendarPopup from "./CalendarPopup";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./scss/CustomCalendar.scss";

const localizer = momentLocalizer(moment);

const CalendarView = ({ machineid, machineType }) => {
  const [events, setEvents] = useState([]);
  const [selectedevent, setSelectedevent] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const closePopup = () => {
    setPopupVisible(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    if (!showPopup) {
      // Optional: additional logic when popup is opened
    }
    fetchData();
  };

  const transformData = (data) => {
    return data.map((item) => ({
      uuid: item?.uuid,
      title: item?.maintenance_task?.taskName,
      start: new Date(item.maintenance_date),
      end: new Date(item.maintenance_date),
    }));
  };

  const onLoad = async () => {
    try {
      const response = await maintenanceService.getbyMachineid(machineid);
      // console.log(response,"===================");
      var data;
      if (Object.values(response).length > 0) {
        data = transformData(response);
      }
      // console.log(data);
      setEvents(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    await onLoad();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [machineid]);

  const handleSelectEvent = (event) => {
    setSelectedevent(event);
    togglePopup();
    console.log(event, "event");
  };

  const handleSelectSlot = (slotInfo) => {
    // console.log("Selected slot:", slotInfo);
    setSelectedSlot(slotInfo);
    setPopupVisible(true);
  };

  return (
    <div className="calendar-container">
      <div className="row p-1">
        <div className="col-12 d-flex justify-content-end">
          {/* {!showPopup && (
            <button className="btn-custom-green" onClick={togglePopup}>
              <small>Add Maintenance +</small>
            </button>
          )} */}
          {showPopup && (
            <AddMaintenance
              onClose={togglePopup}
              machineid={machineid}
              events={selectedevent}
            />
          )}
        </div>
      </div>
      {!showPopup && (
        <div className="row">
          <div className="col-12">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              defaultView="month"
              views={["month"]}
            />
            <CalendarPopup
              show={popupVisible}
              onClose={closePopup}
              slotInfo={selectedSlot}
              machineTypeID={machineType}
              machine_id={machineid}
              load={onLoad}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarView;
