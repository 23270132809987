import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "../../views/Cards";
import machineService from "../../services/machine/machineService";
import siteService from "../../services/site/site.service";
import tokenHelpers from "../../helpers/token.helpers";
import reportService from "../../services/Report/report.service";
import DonutChart from "../../views/DonutChart";
import Paginationtable from "../Table/Pagintiontable";
import Loader from "../loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faArrowTrendDown,
} from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import {
  siteName,
  atomSum,
  atomUtility,
  unitDifference,
} from "../../global/atom";

const Site = () => {
  const [data, setData] = useAtom(siteName);
  const [atomsum, setatomSum] = useAtom(atomSum);
  const [atomutility, setatomUtility] = useAtom(atomUtility);
  const [unitdifference, setatomunitDifference] = useAtom(unitDifference);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(tokenHelpers.getUser());
  const siteid = tokenHelpers.getSiteId();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [today, setToday] = useState(0);
  const [yesterday, setYesterday] = useState(0);
  const [thisweek, setThisweek] = useState(0);
  const [lastweek, setLastweek] = useState(0);
  const [thismonth, setThismonth] = useState(0);
  const [lastmonth, setLastmonth] = useState(0);
  const [utility, setUtility] = useState(1);
  const [machines, setMachines] = useState([]);
  const [siteDetails, setSiteDeatils] = useState({});
  const [machineType, setMachineType] = useState({});

  const [sum, setSum] = useState("");

  const [activeTab, setActiveTab] = useState("overall");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const getsiteDetails = async () => {
    try {
      const response = await siteService.getSitebyUUID(uuid);
      if (response && Object.keys(response).length > 0) {
        setSiteDeatils(response);
        setData(response?.site_name);
      }
    } catch (err) {
      console.error("Error fetching site details:", err);
    }
  };

  const realtimeData = async () => {
    try {
      const response = await machineService.getMachinebySite(
        user.clientId,
        siteid
      );

      if (Array.isArray(response)) {
        setMachines(response);
        let vtoday = 0;
        let vyesterday = 0;
        let vthisweek = 0;
        let vlastweek = 0;
        let vthismonth = 0;
        let vlastmonth = 0;
        const typewiseData = {};

        await Promise.all(
          response.map(async (machine) => {
            const machineResponse = await machineService.getmachinesummary(
              machine.id
            );

            if (machine.incomer === 1) {
              if (machineResponse.today[0]) {
                vtoday += machineResponse.today[0].data
                  ? parseFloat(machineResponse.today[0].data["kwh_units"])
                  : 0;
                var typeName = machine.machinetype.type_name;
                if (typewiseData.hasOwnProperty(typeName)) {
                  typewiseData[typeName] = parseInt(vtoday);
                } else {
                  typewiseData[typeName] = parseInt(vtoday);
                }
              }

              if (machineResponse.yesterday[0])
                vyesterday += machineResponse.yesterday[0].data
                  ? parseFloat(machineResponse.yesterday[0].data["kwh_units"])
                  : 0;

              if (machineResponse.thismonth[0])
                vthismonth += machineResponse.thismonth[0].data
                  ? parseFloat(machineResponse.thismonth[0].data["kwh_units"])
                  : 0;

              if (machineResponse.lastmonth[0])
                vlastmonth += machineResponse.lastmonth[0].data
                  ? parseFloat(machineResponse.lastmonth[0].data["kwh_units"])
                  : 0;

              if (machineResponse.thisweek[0])
                vthisweek += machineResponse.thisweek[0].data
                  ? parseFloat(machineResponse.thisweek[0].data["kwh_units"])
                  : 0;

              if (machineResponse.lastweek[0])
                vlastweek += machineResponse.lastweek[0].data
                  ? parseFloat(machineResponse.lastweek[0].data["kwh_units"])
                  : 0;
            }
          })
        );

        setToday(vtoday.toFixed(0));
        setYesterday(vyesterday.toFixed(0));
        setThisweek(vthisweek.toFixed(0));
        setLastweek(vlastweek.toFixed(0));
        setThismonth(vthismonth.toFixed(0));
        setLastmonth(vlastmonth.toFixed(0));

        setMachineType(typewiseData);
        const kwhArry = Object.values(typewiseData);
        const sumKwh = kwhArry.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setSum(sumKwh);
        setatomSum(sumKwh);

        // console.log(typewiseData, "typewiseData");
      }
    } catch (err) {
      console.error("Error fetching real-time data:", err);
    }
  };

  const getMaxDateForId = (data, siteid) => {
    const filteredData = data.filter((item) => item.siteID == siteid);
    if (filteredData.length > 0) {
      const maxDate = filteredData.reduce((max, current) => {
        return new Date(current.eb_month) > new Date(max.eb_month)
          ? current
          : max;
      });
      return maxDate.eb_amount;
    }
    return false;
  };

  const getUtility = async () => {
    try {
      const response = await reportService.utilities(user.clientId);
      if (Array.isArray(response)) {
        setUtility(getMaxDateForId(response, siteid));
        setatomUtility(getMaxDateForId(response, siteid));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await getUtility();
    await getsiteDetails();
    await realtimeData();
    setLoading(false);
  };

  // const handleNavigate = (id) => {
  //   navigate(`/app/site/machines/${id}`);
  // };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [machines]);

  useEffect(() => {
    var uDifference = (((today - yesterday) / yesterday) * 100).toFixed(2);
    setatomunitDifference(uDifference);
    // console.log(uDifference, today, yesterday, "uDifference");
  }, [today]);

  return (
    <div className="">
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
          <div className="py-3">
            <b>Overall Unit</b>
          </div>
          <div className="row">
            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="Today"
                units={today}
                amount={today * utility}
                bgcolor="bg-grey"
              />
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="Yesterday"
                units={yesterday}
                amount={yesterday * utility}
              />
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="This Week"
                units={thisweek}
                amount={thisweek * utility}
              />
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="Last Week"
                units={lastweek}
                amount={lastweek * utility}
              />
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="This Month"
                units={thismonth}
                amount={thismonth * utility}
              />
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <Card
                title="Last Month"
                units={lastmonth}
                amount={lastmonth * utility}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="row p-2">
                <ul className="nav nav-tabs">
                  {["overall", "break-down"].map((tab) => (
                    <li className="nav-item" key={tab} style={{ flex: 1 }}>
                      <button
                        className={`nav-link ${
                          activeTab === tab ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(tab)}
                        style={{
                          backgroundColor: activeTab === tab ? "#FFA31A" : "",
                          color: activeTab === tab ? "#ffffff" : "#000000",
                          borderRadius: "5px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {tab
                          .replace("-", " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </button>
                    </li>
                  ))}
                </ul>

                <div className="tab-content">
                  <div
                    id="overall"
                    className={`tab-pane fade ${
                      activeTab === "overall" ? "show active" : ""
                    }`}
                  >
                    <div className="row py-5">
                      <div className="col-12 d-flex justify-content-center">
                        <DonutChart
                          dataLable={Object.keys(machineType)}
                          dataSeries={Object.values(machineType)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="break-down"
                    className={`tab-pane fade ${
                      activeTab === "break-down" ? "show active" : ""
                    }`}
                  >
                    <div className="row p-2">
                      <div className="col-12">
                        <Paginationtable
                          data={machines}
                          navigateColumn={{
                            link: "/app/site/machines/",
                            text: "Check now",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right column for other content */}
        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          {/* First section */}
          <div className="bg-grey fixed-right">
            <div className="p-2">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 font-orange">
                  <b>{siteDetails.site_name}</b>
                </div>
                <div className="py-4 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 py-1">
                  <small>Total Consumption Energy</small>
                </div>
                {!isNaN(unitdifference) && (
                  <div
                    className="py-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-1"
                    style={{ color: unitdifference >= 0 ? "green" : "red" }}
                  >
                    {unitdifference < 0 ? (
                      <FontAwesomeIcon icon={faArrowTrendDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowTrendUp} />
                    )}{" "}
                    {unitdifference > 0 ? unitdifference : unitdifference * -1}
                  </div>
                )}
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-1">
                  <b>{sum} kwh</b>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-1 d-flex justify-content-end currency-symbol">
                  <b>{sum * utility}</b>
                </div>
              </div>

              <div className="row p-3">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center"></div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center"></div>
              </div>
            </div>
          </div>

          {/* Second section */}
        </div>
      </div>
    </div>
  );
};

export default Site;
