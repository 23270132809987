import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import twoD from "../../assets/images/twoD.png";
import viewSite from "../../assets/images/viewSite.png";
import siteService from "../../services/site/site.service";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import tokenHelpers from "../../helpers/token.helpers";
import CircleMap from "../CircleMap/CircleMap";
import Loader from "../loader/Loader";

const Digitaltwin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const uuid = tokenHelpers.getSiteuuid();
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState([]);
  const divStyle = {
    position: "relative",
    height: "180px",
  };

  const imageStyle = {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    // height: 'auto',
  };

  const buttonStyle = {
    position: "absolute",
    bottom: "10px", // Adjust the bottom spacing as needed
    left: "50%",
    transform: "translateX(-50%)", // Center the button horizontally
    zIndex: 1,
  };

  const handleOnLoad = async () => {
    try {
      setLoading(true);
      const response = await siteService.getSitebyUUID(uuid);
      if (response.sections) {
        setSections(response.sections);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleOnchange = (e) => {
    setSection(e.target.value);
  };
  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleNavigate = () => {
    if (section.length > 0) {
      navigate(`/app/site/machines/${section}?section=true`);
    }
  };
  return (
    <div className="">
      <div className="row">
        {/* Left column for the image */}
        <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9 d-flex justify-content-center">
          <img
            src={twoD}
            className="img-fluid"
            style={{
              maxHeight: "100vh",
              maxWidth: "100%",
              borderRadius: "5px",
            }}
            alt="Site Image"
          />
        </div>

        {/* Right column for other content */}
        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          {/* First section */}
          <div className="bg-grey fixed-right">
            <div className="p-2">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <small>Overall Unit</small>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-end">
                  <div
                    className="badge badge-pill badge-class p-2"
                    style={{ borderRadius: "50px" }}
                  >
                    <small>
                      <FontAwesomeIcon icon={faBolt} />
                      333 kwh
                    </small>
                  </div>
                </div>
              </div>

              <div className="row p-3">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center ">
                  <select className="form-control form-control-sm rounded-pill bg-transparent text-white border-1 shadow-none px-3 select-class">
                    <option>All Units</option>
                  </select>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center">
                  <select className="form-control form-control-sm rounded-pill bg-transparent text-white border-1 shadow-none px-3 select-class">
                    <option>Yesterday</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <CircleMap />
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-12">
                    <div>
                      <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-3">
                          Visit Section
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-3">
                          <select
                            className="form-control-sm w-100"
                            onChange={(e) => {
                              handleOnchange(e);
                            }}
                            style={{ height: "30px", color: "black" }}
                          >
                            <option value="">Select Section</option>
                            {sections &&
                              sections.map((section, index) => (
                                <option value={section.id} key={index}>
                                  {section.section}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="p-2" style={divStyle}>
                      <div>
                        <div>
                          <button
                            className="btn-custom-gradient"
                            onClick={() => {
                              handleNavigate();
                            }}
                            style={buttonStyle}
                          >
                            Visit Now &#8599;
                          </button>
                        </div>
                      </div>
                      <img
                        src={viewSite}
                        alt="Description"
                        style={imageStyle}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Your content here */}
          </div>

          {/* Second section */}
        </div>
      </div>
    </div>
  );
};

export default Digitaltwin;
