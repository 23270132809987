import React, { createContext, useState, useContext } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(true);
  const [energyIndex, setEnergyIndex] = useState(false);

  return (
    <ThemeContext.Provider
      value={{ darkTheme, setDarkTheme, energyIndex, setEnergyIndex }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
