import React, { useEffect, useRef } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import headerService from "../../services/header/header.service";
import tokenHelpers from "../../helpers/token.helpers";
import { useAtom } from "jotai";
import { selectedSiteID } from "../../global/atom";
import faradayIcon from "../../assets/images/FaradayThunderIcon.png";
import MarkerClusterer from "@googlemaps/markerclustererplus";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
  //   borderRadius: "5px",
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

const options = {
  disableDefaultUI: false,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

const snazzyMapStyle = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

const GMap = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBSXKnMaeKIJj7k176_xhfN2VKRIC4K1VA", // Google Maps API key
  });

  const auth_user = JSON.parse(tokenHelpers.getUser());
  const [atomSiteID, setSiteID] = useAtom(selectedSiteID);
  const navigate = useNavigate();
  const mapRef = useRef();

  useEffect(() => {
    const loadMapData = async () => {
      try {
        var markerData = [];
        tokenHelpers.removeSiteId();
        const siteList = await headerService.getSiteList(auth_user.clientId);

        if (siteList) {
          siteList.forEach((item) =>
            markerData.push({
              id: item.uuid,
              position: {
                lat: Number(item.latitude),
                lng: Number(item.longitude),
              },
              title: item.site_name,
              siteid: item.id,
            })
          );

          // Initialize the map and clusterer
          const map = mapRef.current;
          const markers = markerData.map((markerInfo) => {
            const marker = new window.google.maps.Marker({
              position: markerInfo.position,
              map,
              title: markerInfo.title,
              id: markerInfo.id,
              siteid: markerInfo.siteid,
              icon: {
                url: faradayIcon,
                scaledSize: new window.google.maps.Size(30, 30), // Adjust the size (width, height)
              },
            });

            marker.addListener("click", () => {
              handleMarkerClick(markerInfo);
            });

            return marker;
          });

          // Add the MarkerClusterer to manage marker clusters
          new MarkerClusterer(map, markers, {
            // styles: { backgroundColor: "#faa31d" },
            imagePath:
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // URL for cluster images
          });

          const handleMarkerClick = (markerInfo) => {
            tokenHelpers.setSiteId(markerInfo.siteid);
            setSiteID(markerInfo.siteid);
            tokenHelpers.setSiteuuid(markerInfo.id);
            navigate(`/app/site/energy/${markerInfo.id}`);
          };
        }
      } catch (error) {
        // Handle error
      }
    };

    if (isLoaded) {
      loadMapData();
    }
  }, [isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={5}
            center={center}
            options={{ ...options, styles: snazzyMapStyle }}
            onLoad={(map) => (mapRef.current = map)}
          ></GoogleMap>
        </div>
      </div>
    </div>
  );
};

export default GMap;
