import React, { useState, useEffect } from "react";
import ControllerCard from "../../views/ControllerCard";
import controllerService from "../../services/Controller/controller.service";
import AddScehdule from "../../views/AddScehdule";
import Loader from "../loader/Loader";
const Controller = () => {
  const [controllers, setControllers] = useState([]);
  const [controllerDetails, setDetails] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const [loading, setLoading] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handleOnLoad = async () => {
    setLoading(true);
    const response = await controllerService.getAll();
    setControllers(response);
    setLoading(false);
  };

  const handleOnClick = (controller) => {
    setDetails(controller);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);
  useEffect(() => {
    if (controllers.length > 0) {
      setDetails(controllers[0]);
    }
  }, [controllers]);

  const divStyle = {
    height: "75%",
    borderRadius: "5px",
  };

  const handlePowerToggle = async (id, power) => {
    setLoading(true);
    var status = !power ? "on" : "off";

    await controllerService.action({
      action: status,
      macid: id,
    });
    handleOnLoad();
    setLoading(false);
  };

  return (
    <>
      <div className="">
        {loading && <Loader />}
        <div className="row">
          {/* Left column for the image */}
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
            <div className="text-center"></div>
            <div className="row mt-3">
              {/* <div className="col-4 text-center"> */}
              {showPopup && (
                <AddScehdule
                  onClose={togglePopup}
                  controllerDetails={controllerDetails}
                />
              )}
              {!showPopup &&
                controllers.map((controller, index) => (
                  <div
                    key={index}
                    className="col-4 mb-2"
                    onClick={() => {
                      handleOnClick(controller);
                    }}
                  >
                    <ControllerCard
                      controller={controller}
                      handlePowerToggle={handlePowerToggle}
                      togglePopup={togglePopup}
                    />
                  </div>
                ))}
              {/* </div> */}
            </div>
          </div>

          {/* Right column for other content */}
          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            {/* First section */}
            <div className="bg-grey fixed-right">
              <div className="px-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center font-robot-21">
                    <span className="font-orange">Device Details</span>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-4 h-100 font-robot-13">Macid : </div>
                  <div className="col-8 h-100 ">
                    <span>{controllerDetails?.macid}</span>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-4 h-100 font-robot-13">Site : </div>
                  <div className="col-8 h-100 ">
                    <span>{controllerDetails?.site?.site_name}</span>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-4 h-100 font-robot-13">Location : </div>
                  <div className="col-8 h-100 ">
                    <span>{controllerDetails?.site?.location}</span>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-4 h-100 font-robot-13">Status : </div>
                  <div className="col-8 h-100 ">
                    <span>
                      {controllerDetails?.power ? (
                        <span style={{ color: "green" }}>On</span>
                      ) : (
                        <span style={{ color: "red" }}>Off</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {/* Your content here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Controller;
