import { lastDayOfMonth } from "rsuite/esm/internals/utils/date";
import api from "../interceptors/axios.interceptor";
import moment from "moment/moment";

const getFloor = async (clientId) => {
  try {
    const response = await api.get(`sites/client/${clientId}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const utilities = async (clientId) => {
  try {
    const response = await api.get(`utilities/client/${clientId}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const sectionData = async (id) => {
  try {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Get the next month
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const fromdate = `${year}-${month}-01`;
    const todate = `${year}-${month}-${lastDayOfMonth}`;
    const type = "daily";
    const response = await api.get(
      `reports/site/${id}?fromdate=${fromdate}&todate=${todate}&type=${type}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const getReport = async (
  site,
  section,
  machine,
  type,
  format,
  amount,
  date
) => {
  try {
    const response = await api.get(
      `reports/sitereport/${site}/${section}/${machine}/${type}/${format}/${amount}/${date}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const download = async (filename) => {
  try {
    const response = await api.get(`reports/download/${filename}`, {
      responseType: "blob",
    });

    if (response) {
      return response.data;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getToday = async (siteID) => {
  try {
    const date = moment().format("YYYY-MM-DD");
    const response = await api.get(
      `reports/site/${siteID}?fromdate=${date}T00:00:00&todate=${date}T23:59:59`
    );

    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getYesterday = async (siteID) => {
  try {
    const date = moment().subtract(1, "days").format("YYYY-MM-DD");
    const response = await api.get(
      `reports/site/${siteID}?fromdate=${date}T00:00:00&todate=${date}T23:59:59`
    );

    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getWeek = async (siteID) => {
  try {
    const Startdate = moment().startOf("week").format("YYYY-MM-DD");
    const enddate = moment().endOf("week").format("YYYY-MM-DD");
    const response = await api.get(
      `reports/site/${siteID}?fromdate=${Startdate}T00:00:00&todate=${enddate}T23:59:59`
    );

    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getLastWeek = async (siteID) => {
  try {
    const Startdate = moment()
      .subtract(7, "days")
      .startOf("week")
      .format("YYYY-MM-DD");
    const enddate = moment()
      .subtract(7, "days")
      .endOf("week")
      .format("YYYY-MM-DD");

    const response = await api.get(
      `reports/site/${siteID}?fromdate=${Startdate}T00:00:00&todate=${enddate}T23:59:59`
    );

    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getMonth = async (siteID) => {
  try {
    const date = moment().format("YYYY-MM-DD");
    const Startdate = moment().startOf("month").format("YYYY-MM-DD");
    const enddate = moment().endOf("month").format("YYYY-MM-DD");
    const response = await api.get(
      `reports/site/${siteID}?fromdate=${Startdate}T00:00:00&todate=${enddate}T23:59:59`
    );

    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};

const getLastMonth = async (siteID) => {
  try {
    const Startdate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const enddate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    const response = await api.get(
      `reports/site/${siteID}?fromdate=${Startdate}T00:00:00&todate=${enddate}T23:59:59`
    );
    if (response) {
      return response;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    throw error;
  }
};
export default {
  getFloor,
  utilities,
  getReport,
  download,
  getToday,
  getYesterday,
  getWeek,
  getLastWeek,
  getMonth,
  getLastMonth,
  sectionData,
};
