import React, { useState, useEffect } from "react";
import Gmap from "./Gmap";
import Loader from "../loader/Loader";
import headerService from "../../services/header/header.service";
import tokenHelpers from "../../helpers/token.helpers";
import reportService from "../../services/Report/report.service";
import { useTheme } from "../Theme/ThemeContext";
import moment from "moment";
import EnergyIndex from "../specificEneergyCard/EnergyIndex";

const Dashboard = () => {
  const { darkTheme, energyIndex } = useTheme();
  const [sites, setSites] = useState([]);
  const [units, setUnits] = useState({});
  const [utilities, setUtilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility
  const curDate = moment().format("DD/MM/YYYY");

  const getSiteData = async () => {
    try {
      setLoading(true);
      const User = JSON.parse(tokenHelpers.getUser());
      tokenHelpers.removeSiteId();
      const response = await headerService.getSiteList(User.clientId);
      setSites(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSiteData();
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      if (sites.length > 0) {
        let totalUnits = {};

        const results = await Promise.all(
          sites.map(async (item) => {
            const data = await getTodayReport(item.id);
            return { siteID: item.id, units: data.totalunits };
          })
        );

        results.forEach((result) => {
          totalUnits[result.siteID] = parseInt(result.units);
        });

        setUnits(totalUnits);
      }
      setLoading(false);
    };

    fetchReports();
    handleUtilities();
  }, [sites]);

  const getTodayReport = async (siteID) => {
    try {
      const response = await reportService.getToday(siteID);
      return { totalunits: response.totalunits };
    } catch (err) {
      console.log(err);
      return { totalunits: 0 };
    }
  };

  const handleUtilities = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(tokenHelpers.getUser());
      const response = await reportService.utilities(user.clientId);
      setUtilities(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const getMaxDateForId = (data, siteid) => {
    const filteredData = data.filter((item) => item.siteID === siteid);
    if (filteredData.length > 0) {
      const maxDate = filteredData.reduce((max, current) => {
        return new Date(current.eb_month) > new Date(max.eb_month)
          ? current
          : max;
      });
      return maxDate.eb_amount;
    }
    return false;
  };

  return (
    <div className="">
      {loading && <Loader />}
      <div className="row">
        <div className="">
          <Gmap />
        </div>
      </div>

      {/* Grid Icon to toggle popup */}

      {/* Popup Menu */}
      {energyIndex && (
        <div
          style={{
            position: "absolute",
            top: 100,
            right: 50,
            width: "350px",
            backgroundColor: "#f8fafd", //#F6F5F5
            padding: "10px",
          }}
        >
          {/* <h5 className="px-2">Specific Energy Index</h5> */}
          {sites.length > 0 &&
            sites.map((site, index) => (
              <EnergyIndex
                siteName={site.site_name}
                amount={
                  (units[site.id] * getMaxDateForId(utilities, site.id)) / 1000
                }
                curDate={curDate}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
