import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MachineImage from "../../assets/images/machinIndividual.png";
import tokenHelpers from "../../helpers/token.helpers";
import Cards from "../../views/Cards";
import LineChart from "../../views/LineChart";
import HorizontalBarChart from "../../views/HorizontalBarChart";
import CalendarEvents from "../../views/CalendarView";
import GaugeChart from "react-gauge-chart";
import machineService from "../../services/machine/machineService";
import reportService from "../../services/Report/report.service";
import charthelper from "../../helpers/chartData";
import MachineAnalysis from "./MachineAnalysis";
import Loader from "../loader/Loader";

const MachineDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSection = searchParams.get("section");
  const [loading, setLoading] = useState(true);
  const divStyle = {
    backgroundImage: `url(${MachineImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "50vh",
    borderRadius: "5px",
  };
  const [activeTab, setActiveTab] = useState("asset-details");
  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState("");
  const [machineid, setMachineid] = useState("");
  const [machineTypeid, setMachineTypeid] = useState("");
  const [asset, setAsset] = useState("");
  const user = JSON.parse(tokenHelpers.getUser());
  const site = JSON.parse(tokenHelpers.getSiteId());
  const [machineSummary, setMachineSummary] = useState([]);
  const [today, setToday] = useState(0);
  const [yesterday, setYesterday] = useState(0);
  const [thisweek, setThisweek] = useState(0);
  const [lastweek, setLastweek] = useState(0);
  const [thismonth, setThismonth] = useState(0);
  const [lastmonth, setLastmonth] = useState(0);
  const [utility, setUtility] = useState(0);
  const [lineChartData, setLineChartData] = useState({});
  const [kwhsum, SetKwhsum] = useState(0);

  const params = useParams();
  var sectionID;
  var machine_id;
  if (isSection != "true") {
    machine_id = params.machine_id;
  } else {
    sectionID = params.machine_id;
  }
  const handleInitialDropdownLoad = async () => {
    try {
      setLoading(true);
      const response = await machineService.getMachinebySite(
        user.clientId,
        site
      );
      if (Array.isArray(response)) {
        var machineBySection = [];
        if (isSection == "true") {
          machineBySection = response.filter(
            (item) => item.sectionId == sectionID
          );
          setMachines(machineBySection);
        } else {
          setMachines(response);
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getAssetDetails = async (selectedMachine) => {
    try {
      setLoading(true);
      const response = await machineService.getMachinebyuuid(selectedMachine);
      if (typeof response === "object") {
        setAsset(response);
        // console.log("asset", response);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const selectedMachine = e.target.value;
    setMachine(selectedMachine);
    getAssetDetails(selectedMachine);
    getMachineSummary();
    handleChart();
  };

  const getMachineSummary = async () => {
    try {
      setLoading(true);
      if (machine != "") {
        const machineid = machines.filter((item) => item.uuid == machine);
        setMachineid(machineid[0].id);
        setMachineTypeid(machineid[0].machine_type);
        
        const response = await machineService.getmachinesummary(
          machineid[0].id
        );
        if (Array.isArray(response)) {
          setMachineSummary(response);
        }
        setToday(parseInt(response.today[0]?.data?.kwh_units));
        setYesterday(parseInt(response.yesterday[0]?.data?.kwh_units));
        setThisweek(parseInt(response.thisweek[0]?.data?.kwh_units));
        setLastweek(parseInt(response.lastweek[0]?.data?.kwh_units));
        setThismonth(parseInt(response.thismonth[0]?.data?.kwh_units));
        setLastmonth(parseInt(response.lastmonth[0]?.data?.kwh_units));
        // console.log("response", response);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChart = async (e) => {
    try {
      setLoading(true);
      const type = e?.target?.value || "hourly";
      const today = new Date();
      let fromdate, todate;

      // Function to format date
      const formatDate = (date, time) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}T${time}`;
      };

      // Date calculations based on type
      if (type === "hourly") {
        fromdate = formatDate(today, "00:00:00");
        todate = formatDate(today, "23:59:59");
      } else if (type === "daily") {
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        fromdate = formatDate(firstDayOfMonth, "00:00:00");
        todate = formatDate(lastDayOfMonth, "23:59:59");
      } else if (type === "monthly") {
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
        fromdate = formatDate(firstDayOfYear, "00:00:00");
        todate = formatDate(lastDayOfYear, "23:59:59");
      }

      // Filter machines and get machine data
      const machineid = machines.find((item) => item.uuid === machine);
      if (machineid) {
        const response = await machineService.getmachinedata(
          machineid.id,
          fromdate,
          todate,
          type
        );
        const data = charthelper.chartData(response, type);
        setLineChartData(data);

        // Calculate total kWh
        const kwhtotal = response.reduce(
          (sum, item) => sum + (parseFloat(item.data.kwh_units) || 0),
          0
        );
        SetKwhsum(parseFloat(kwhtotal).toFixed(2));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const getMaxDateForId = (data, siteid) => {
    const filteredData = data.filter((item) => item.siteID === siteid);
    if (filteredData.length > 0) {
      const maxDate = filteredData.reduce((max, current) => {
        return new Date(current.eb_month) > new Date(max.eb_month)
          ? current
          : max;
      });
      return maxDate.eb_amount;
    }
    return false;
  };

  const getUtility = async () => {
    try {
      const response = await reportService.utilities(user.clientId);
      if (Array.isArray(response)) {
        setUtility(getMaxDateForId(response, site));
      }
      // console.log("utility", utility);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    await handleInitialDropdownLoad();
    await getUtility();
  };

  useEffect(() => {
    const functions = async () => {
      try {
        setLoading(true);
        if (machines.length > 0) {
          var mid = "";
          if (isSection != "true") {
            mid = machines.filter((item) => item.id == machine_id);
          } else {
            mid = machines.filter((item) => item.sectionId == sectionID);
          }
          const initialMachine = mid[0].uuid;
          setMachine(initialMachine);
          await getAssetDetails(initialMachine);
          await getMachineSummary();
          await handleChart();
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    functions();
  }, [machines]);

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    getMachineSummary();
    handleChart();
  }, [machine]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className="">
        {loading && <Loader />}
        <div className="row">
          {/* Left column for the image */}
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
            <div className="p-2" style={divStyle}>
              <div className="p-2 d-flex justify-content-end h-100">
                <div>
                  <select
                    className="form-control-sm shadow-none w-100 bgColor"
                    name="machine"
                    value={machine}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    {machines.map((machine, index) => (
                      <option key={index} value={machine.uuid}>
                        {machine.machine_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <ul className="nav nav-tabs">
                {[
                  "asset-details",
                  "energy-efficiency",
                  "calendar",
                  "analytics",
                ].map((tab) => (
                  <li className="nav-item" key={tab} style={{ flex: 1 }}>
                    <button
                      className={`nav-link ${
                        activeTab === tab ? "active" : ""
                      }`}
                      onClick={() => handleTabChange(tab)}
                      style={{
                        backgroundColor: activeTab === tab ? "#FFA31A" : "",
                        color: activeTab === tab ? "#ffffff" : "#000000",
                        borderRadius: "5px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tab
                        .replace("-", " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab-content">
                <div
                  id="asset-details"
                  className={`tab-pane fade ${
                    activeTab === "asset-details" ? "show active" : ""
                  }`}
                >
                  <div className="row p-2">
                    <div className="col-3">Asset ID -</div>
                    <div className="col-9">{asset?.macid}</div>
                  </div>

                  <div className="row p-2">
                    <div className="col-3">Location -</div>
                    <div className="col-9">
                      {asset?.site?.location}-{asset?.section?.section}
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-3">Year of Manufacture -</div>
                    <div className="col-9">
                      {formatDate(asset.erection_date)}
                    </div>
                  </div>
                </div>
                <div
                  id="energy-efficiency"
                  className={`tab-pane fade ${
                    activeTab === "energy-efficiency" ? "show active" : ""
                  }`}
                >
                  <div className="row p-2">
                    <div className="col-2">
                      <Cards
                        title="Today"
                        amount={today * utility}
                        units={today}
                        bgcolor="bg-grey"
                      />
                    </div>
                    <div className="col-2">
                      <Cards
                        title="Yesterday"
                        amount={yesterday * utility}
                        units={yesterday}
                      />
                    </div>

                    <div className="col-2">
                      <Cards
                        title="This Week"
                        amount={thisweek * utility}
                        units={thisweek}
                      />
                    </div>

                    <div className="col-2">
                      <Cards
                        title="Last Week"
                        amount={lastweek * utility}
                        units={lastweek}
                      />
                    </div>

                    <div className="col-2">
                      <Cards
                        title="This Month"
                        amount={thismonth * utility}
                        units={thismonth}
                      />
                    </div>

                    <div className="col-2">
                      <Cards
                        title="Last Month"
                        amount={lastmonth * utility}
                        units={lastmonth}
                      />
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-3">Unit analytics</div>
                    {/* <div className="col-2">Energy Produce</div> */}
                    <div className="col-3">
                      <span
                        style={{
                          backgroundColor: "#179A00",
                          borderRadius: "50%",
                          width: "10px",
                          height: "10px",
                          display: "inline-block",
                        }}
                      ></span>
                      Energy Consumption
                    </div>
                    <div className="col-2">{kwhsum} kwh</div>
                    <div className="col-4">
                      <select
                        className="form-control form-control-sm rounded-pill bg-transparent border-1 shadow-none px-3 select-class"
                        onChange={(e) => {
                          handleChart(e);
                        }}
                      >
                        <option value={"hourly"}>Hourly</option>
                        <option value={"daily"}>Daily</option>
                        <option value={"monthly"}>Monthly</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <LineChart
                        dataLable={lineChartData?.x}
                        dataSeries={lineChartData?.y}
                        order={true}
                      />
                    </div>
                  </div>
                </div>
                <div
                  id="calendar"
                  className={`tab-pane fade ${
                    activeTab === "calendar" ? "show active" : ""
                  }`}
                >
                  <div className="row d-flex justify-content-center">
                    <div className="col-12" style={{ width: "90%" }}>
                      <CalendarEvents
                        machineid={machineid}
                        machineType={machineTypeid}
                      />
                    </div>
                  </div>
                </div>

                <div
                  id="analytics"
                  className={`tab-pane fade ${
                    activeTab === "analytics" ? "show active" : ""
                  }`}
                >
                  {machines.length > 0 && (
                    <MachineAnalysis
                      machine={machineid}
                      allMachines={machines}
                      ThisMonthUnit={thismonth}
                    />
                  )}
                </div>

                {/* <div
                  id="analytics-index"
                  className={`tab-pane fade ${
                    activeTab === "analytics-index" ? "show active" : ""
                  }`}
                >
                  <p>Coming Soon.</p>
                </div> */}
              </div>
            </div>
          </div>

          {/* Right column for other content */}
          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            {/* First section */}
            <div className="bg-grey fixed-right">
              <div className="row p-3">
                <div className="col-6">Status</div>
                <div className="col-6">Running Status</div>
              </div>

              <div className="row p-3">
                <div className="col-6 d-flex">
                  <span className="criticalDot"></span>
                </div>
                <div className="col-6 d-flex alin-items-center justify-content-center">
                  <span className="runningDot"></span>
                </div>
              </div>

              <div className="row p-3">
                <div className="col-6 d-flex">
                  <span className="">Semi Critical</span>
                </div>
                <div className="col-6 d-flex alin-items-center justify-content-center">
                  <span className="">Running</span>
                </div>
              </div>
              <hr />
              <div className="p-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                    <small>Machine Efficiency</small>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12  mb-5 centered-chart">
                    <HorizontalBarChart
                      labels={[""]}
                      dataSeries={[40, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                      lable="Efficiency"
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <hr />
                <div className="col-12 mb-5 centered-chart">
                  <div className="mt-3">
                    <small>Machine Health</small>
                    <GaugeChart
                      className="py-4"
                      colors={["#FF5F6D", "#FFC371", "#4CAF50"]}
                      nrOfLevels={5}
                      percent={0.75}
                      needleColor="#ddddd"
                      arcWidth={0.4}
                      arcsLength={[0.1, 0.2, 0.3, 0.4]}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Second section */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MachineDashboard;
