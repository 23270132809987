const AUTH_TOKEN = "AUTH_TOKEN";
const AUTH_USER = "AUTH_USER";
const SITE = "site_id";
const SITEUUID = "siteuuid";
const FUEL = "fuel_device";

const setLocalStorage = (title, value) => {
  localStorage.removeItem(title);
  localStorage.setItem(title, value);
};

const getLocalStorage = (title) => {
  return localStorage.getItem(title);
};

const setToken = (token) => {
  setLocalStorage(AUTH_TOKEN, token);
};

const getToken = () => {
  return getLocalStorage(AUTH_TOKEN);
};

const setUser = (user) => {
  setLocalStorage(AUTH_USER, user);
};

const getUser = () => {
  return getLocalStorage(AUTH_USER);
};

const setSiteId = (value) => {
  localStorage.removeItem(SITE);
  localStorage.setItem(SITE, value);
};

const getSiteId = () => {
  return localStorage.getItem(SITE);
};

const removeSiteId = () => {
  localStorage.removeItem(SITE);
};

const setFuel = (value) => {
  localStorage.removeItem(FUEL);
  localStorage.setItem(FUEL, value);
};

const getFuel = () => {
  return localStorage.getItem(FUEL);
};

const setSiteuuid = (value) => {
  localStorage.removeItem(SITEUUID);
  localStorage.setItem(SITEUUID, value);
};

const getSiteuuid = () => {
  return localStorage.getItem(SITEUUID);
};

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_USER);
  localStorage.removeItem(SITE);
  localStorage.removeItem(SITEUUID);
  localStorage.removeItem(FUEL);
};

export default {
  setToken,
  getToken,
  setUser,
  getUser,
  setSiteId,
  getSiteId,
  removeSiteId,
  setFuel,
  getFuel,
  setSiteuuid,
  getSiteuuid,
  logout
};
