import HorizontalBarChart from "../../views/HorizontalBarChart";
import machineService from "../../services/machine/machineService";
import DaywiseData from "../../views/DaywiseData";
import moment from "moment";
import { useEffect, useState } from "react";
const MachineAnalysis = ({ machine, allMachines, ThisMonthUnit }) => {
  const [machineName, setMachineName] = useState("");
  const [dayWiseData, setDayWiseData] = useState({});
  const [dayWiseTotal, setDayWiseTotal] = useState(0);
  const [dateWiseData, setDateWiseData] = useState({});
  const [maximumDay, setMaximumDay] = useState("");
  const [maximumUnits, setMaximumUnits] = useState(0);
  const [maximumDate, setMaximumDate] = useState("");
  const [datewisemaximumUnits, setdatewiseMaximumUnits] = useState(0);
  const currentMonth = moment().format("MMMM");
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getMachineName = () => {
    setMachineName(
      allMachines.filter((item) => item.id == machine)[0].machine_name
    );
  };

  const groupAndSumDataByDayOfWeek = (data) => {
    return data.reduce((acc, item) => {
      const date = new Date(item.createat);
      const dayOfWeek = daysOfWeek[date.getUTCDay()]; // Get day of the week

      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = { items: [], totalValue: 0 };
      }

      acc[dayOfWeek].items.push(item);
      acc[dayOfWeek].totalValue += parseInt(item?.data?.kwh_units);
      return acc;
    }, {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  const groupAndSumDataByDateWise = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.createat);
      const dayOfWeek = daysOfWeek[date.getUTCDay()]; // Get day of the week
      const dateKey = formatDate(date.toISOString().split("T")[0]); // Get date in YYYY-MM-DD format

      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = {};
      }

      if (!acc[dayOfWeek][dateKey]) {
        acc[dayOfWeek][dateKey] = { items: [], totalValue: 0 };
      }

      acc[dayOfWeek][dateKey].items.push(item);
      acc[dayOfWeek][dateKey].totalValue += parseInt(item?.data?.kwh_units);
      return acc;
    }, {});

    // Ensure the result is ordered from Sunday to Saturday
    const orderedGroupedData = {};
    daysOfWeek.forEach((day) => {
      if (groupedData[day]) {
        orderedGroupedData[day] = groupedData[day];
      } else {
        orderedGroupedData[day] = {};
      }
    });

    return orderedGroupedData;
  };

  const daywiseTotal = (atualday) => {
    var total = 0;
    daysOfWeek.map((day) =>
      Object.entries(dateWiseData[day] || {}).map(([date, data]) => {
        if (day == atualday) {
          total += data?.totalValue;
        }
      })
    );
    return total;
  };

  const daywiseSum = (day) => {
    return Object.values(dateWiseData[day] || {}).reduce(
      (daySum, data) => daySum + data?.totalValue,
      0
    );
  };

  const getMaximumConsumed = () => {
    let maxDay = "";
    let maxValue = 0;

    daysOfWeek.forEach((day) => {
      const dayTotal = daywiseSum(day);
      if (dayTotal > maxValue) {
        maxValue = dayTotal;
        maxDay = day;
      }
    });

    setMaximumDay(maxDay);
    setMaximumUnits(maxValue);
  };
  const getMaximumConsumedDate = () => {
    let maxValue = 0;
    let maxDate = "";
    Object.entries(dateWiseData[maximumDay] || {}).forEach(([date, data]) => {
      if (data?.totalValue > maxValue) {
        maxValue = data?.totalValue;
        maxDate = date;
      }
    });
    setdatewiseMaximumUnits(maxValue);
    setMaximumDate(maxDate);
  };

  const handleChart = async (e) => {
    try {
      const type = "daily";
      const today = new Date();
      let fromdate, todate;

      // Function to format date
      const formatDate = (date, time) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}T${time}`;
      };

      // Date calculations based on type
      if (type === "daily") {
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        fromdate = formatDate(firstDayOfMonth, "00:00:00");
        todate = formatDate(lastDayOfMonth, "23:59:59");
      }

      if (machine) {
        const response = await machineService.getmachinedata(
          machine,
          fromdate,
          todate,
          type
        );

        const data = response;
        // console.log(response, "response");
        const groupedData = groupAndSumDataByDayOfWeek(data);
        var total = 0;
        daysOfWeek.map((item) => {
          total += groupedData[item]?.totalValue || 0;
        });
        setDayWiseData(groupedData);
        setDayWiseTotal(total);
        const datewiseData = groupAndSumDataByDateWise(data);
        setDateWiseData(datewiseData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (machine) {
      //   alert(ThisMonthUnit);
      getMachineName();
      handleChart();
    }
  }, [machine]);

  useEffect(() => {
    if (machine) {
      getMaximumConsumed();
    }
  }, [dayWiseData]);

  useEffect(() => {
    if (machine) {
      getMaximumConsumedDate();
    }
  }, [maximumDay]);
  return (
    <>
      <div className="px-2">
        <div className="row">
          <div className="col-6">
            <table className="table table-responsive table-striped table-bordered">
              <thead>
                <tr className="text-center">
                  <th
                    colSpan={2}
                    style={{ backgroundColor: "#193330", color: "white" }}
                  >
                    {currentMonth} Month
                  </th>
                </tr>
                <tr>
                  <th>Machine</th>
                  <th>Units(kwh)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{machineName}</td>
                  <td>{dayWiseTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6" style={{ marginTop: "-10px" }}>
            {/* <div>Chiller Energy Cinsumption</div> */}
            <HorizontalBarChart
              labels={[""]}
              dataSeries={[dayWiseTotal, dayWiseTotal + 10]}
              tickColor="#ddddd"
              barColor="#F00501"
              padding={{ top: 30, bottom: 30 }}
              lable="Units"
            />
          </div>
        </div>

        <div className="row">
          <div>{machineName} Day wise Consumption</div>
          {/* <div className="bg-grey text-white text-center" style={{height:"35px"}}>June Month</div> */}
          <table className="table table-responsive table-striped table-bordered">
            <thead>
              <tr className="text-center">
                <th
                  colSpan={9}
                  style={{ backgroundColor: "#193330", color: "white" }}
                >
                  {currentMonth} Month
                </th>
              </tr>
              <tr>
                <th>Machine</th>
                <th>Sunday</th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{machineName}</td>
                <td>{dayWiseData?.Sunday?.totalValue || 0}</td>
                <td>{dayWiseData?.Monday?.totalValue  || 0}</td>
                <td>{dayWiseData?.Tuesday?.totalValue || 0}</td>
                <td>{dayWiseData?.Wednesday?.totalValue || 0}</td>
                <td>{dayWiseData?.Thursday?.totalValue || 0}</td>
                <td>{dayWiseData?.Friday?.totalValue || 0}</td>
                <td>{dayWiseData?.Saturday?.totalValue || 0}</td>
                <td>{dayWiseTotal}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="row">
          <DaywiseData
            actualDay={"Sunday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>
        <div className="row">
          <DaywiseData
            actualDay={"Monday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>

        <div className="row">
          <DaywiseData
            actualDay={"Tuesday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>
        <div className="row">
          <DaywiseData
            actualDay={"Wednesday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>

        <div className="row">
          <DaywiseData
            actualDay={"Thursday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>

        <div className="row">
          <DaywiseData
            actualDay={"Friday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>
        <div className="row">
          <DaywiseData
            actualDay={"Saturday"}
            dateWiseData={dateWiseData}
            machineName={machineName}
          />
        </div>

        <div className="row">
          <div className="">Inference</div>
          <ul className="px-5" style={{ listStyleType: "disc" }}>
            <li className="py-3">
              The total amount of energy consumed in {machineName} for the month
              of <b>{currentMonth}</b> is <b>{dayWiseTotal}</b> units (kWh).
            </li>
            {/* <li className="py-3">
            For the month of  {currentMonth}, the {machineName} has the highest energy
            consumption with 85038 units (kWh).
          </li> */}

            <li className="py-3">
              For the month of {currentMonth} total energy consumption of{" "}
              {machineName} is highest on <b>{maximumDay}</b> when compared to
              other days with <b>{maximumUnits}</b> units (kWh).
            </li>

            <li className="py-3">
              {machineName} has the highest energy consumption with{" "}
              <b>{datewisemaximumUnits}</b> units (kWh) based on validating the
              energy consumption of all {machineName} on <b>{maximumDay}</b> -{" "}
              <b>{maximumDate}</b> in {currentMonth}.
            </li>

            {/* <li>
            {machineName} has the highest energy consumption with 17222 units (kWh)
            based on validating the energy consumption of all chillers on
            Sundays in June.
          </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MachineAnalysis;
