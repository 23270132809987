import Site from "./components/site/Site";
import Digitaltwin from "./components/DigitalTwin/Digitaltwin";
import AllMachines from "./components/machine/AllMachines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faCogs,
  faBolt,
  faWalking,
  faGasPump,
  faUserShield,
  faThermometerFull,
  faSitemap,
  faIndustry,
  faPause,
  faFile,
  faTurnUp,
} from "@fortawesome/free-solid-svg-icons";
import FuelLevel from "./components/fuel/FuelLevel";
import FuelSummary from "./components/fuel/FuelSummary";
var routesSite = [
  {
    path: "/site/energy/",
    name: "Energy",
    icon: <FontAwesomeIcon icon={faBolt} />,
    component: <Site />,
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/walkin",
    name: "Walk In",
    icon: <FontAwesomeIcon icon={faWalking} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/energy/:uuid",
    name: "Site",
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    component: <Site />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "",
    name: "Disel",
    icon: <FontAwesomeIcon icon={faGasPump} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
    subRoutes: [
      {
        path: "/site/fuel",
        name: "Fuel Level",
        icon: <FontAwesomeIcon icon={faTurnUp} />,
        component: <FuelLevel />,
        layout: "/app",
        isDisplay: "1",
      },
      {
        path: "/site/fuelsummary",
        name: "Fuel Summary",
        icon: <FontAwesomeIcon icon={faFile} />,
        component: <FuelSummary />,
        layout: "/app",
        isDisplay: "1",
      },
    ],
  },
  {
    path: "/site/fuel",
    name: "Fuel Level",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <FuelLevel />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/fuelsummary",
    name: "Fuel Summary",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <FuelSummary />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/floorhead",
    name: "Floor Head",
    icon: <FontAwesomeIcon icon={faUserShield} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/temperature",
    name: "Temperature",
    icon: <FontAwesomeIcon icon={faThermometerFull} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/production",
    name: "Production",
    icon: <FontAwesomeIcon icon={faIndustry} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/sitemachines",
    name: "Machines",
    icon: <FontAwesomeIcon icon={faSitemap} />,
    component: <AllMachines />,
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/site/digitaltwin",
    name: "Digital Twin",
    icon: <FontAwesomeIcon icon={faPause} />,
    component: <Digitaltwin />,
    layout: "/app",
    isDisplay: "1",
  },
];

export default routesSite;
