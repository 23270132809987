import React, { useEffect, useState } from "react";
import maintenanceService from "../services/maintenance/maintenance.service";

const AddMaintenance = ({ onClose, machineid, events }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [currentevent, setCurrentEvent] = useState({});
  const [formData, setFormData] = useState({
    description: "",
    beforeFileName: "",
    afterFileName: "",
  });

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Optional: Trigger any callback on close
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e, type) => {
    const file = e.target.files[0];
    try {
      const response = await maintenanceService.upload(file);
      if (type === "before") {
        setFormData({ ...formData, beforeFileName: response.message });
      } else {
        setFormData({ ...formData, afterFileName: response.message });
      }
    } catch (err) {
      console.error("File upload failed:", err);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.description) {
      errors.description = "Description is required.";
    }
    // if (!values.beforeFileName) {
    //   errors.beforeFile = "Before picture is required.";
    // }
    // if (!values.afterFileName) {
    //   errors.afterFile = "After picture is required.";
    // }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formErrors = validate(formData);
      if (Object.keys(formErrors).length === 0) {
        console.log(formData, "form data");
        await maintenanceService.edit(events.uuid, formData);
        onClose();
      } else {
        setErrors(formErrors);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleLoad = async () => {
    try {
      const response = await maintenanceService.getbyUUID(events.uuid);

      setCurrentEvent(response[0]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  useEffect(() => {
    console.log(currentevent["before_image"], "currentevent==============");
  }, [currentevent]);

  return (
    <>
      {isOpen && (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Maintenance</h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Description : </label>
                    {Object.keys(currentevent).length > 0 &&
                    currentevent["description"] == "" ? (
                      <textarea
                        className="form-control shadow-none"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    ) : (
                      <label className="form-control">
                        {currentevent?.description}
                      </label>
                    )}

                    {errors.description && (
                      <p className="text-danger">{errors.description}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date</label>
                    <input
                      type="date"
                      className="form-control shadow-none"
                      name="maintenance_date"
                      value={formData.maintenance_date}
                      onChange={handleChange}
                    />
                    {errors.maintenance_date && (
                      <p className="text-danger">{errors.maintenance_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control shadow-none"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">Select status</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="completed">Completed</option>
                    </select>
                    {errors.status && (
                      <p className="text-danger">{errors.status}</p>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Before Picture : </label>
                    {Object.keys(currentevent).length > 0 &&
                    currentevent["before_image"] == "" ? (
                      <input
                        type="file"
                        className="form-control-file square-file-input"
                        name="beforeFileName"
                        onChange={(e) => handleFileChange(e, "before")}
                        accept="image/*"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/images/${currentevent.before_image}`}
                        width="90"
                        alt="Before"
                      />
                    )}

                    {errors.beforeFile && (
                      <p className="text-danger">{errors.beforeFile}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>After Picture : </label>
                    {Object.keys(currentevent).length > 0 &&
                    currentevent["after_image"] == "" ? (
                      <input
                        type="file"
                        className="form-control-file square-file-input"
                        name="afterFileName"
                        onChange={(e) => handleFileChange(e, "after")}
                        accept="image/*"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/images/${currentevent.after_image}`}
                        width="60"
                        height="60"
                        style={{ maxWidth: 60, maxHeight: 60 }}
                        alt="After"
                      />
                    )}

                    {errors.afterFile && (
                      <p className="text-danger">{errors.afterFile}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {Object.keys(currentevent).length > 0 &&
                  currentevent["description"] == "" && (
                    <button type="submit" className=" btn-custom-green">
                      Save
                    </button>
                  )}
                &nbsp;
                <button
                  type="button"
                  className=" btn-custom-green"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddMaintenance;
