import React from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

const EnergyIndex = ({ siteName, amount, curDate }) => {
  // Dummy data for the chart
  const data = [
    { name: "Page A", value: 400 },
    { name: "Page B", value: 300 },
    { name: "Page C", value: 500 },
    { name: "Page D", value: 200 },
    { name: "Page E", value: 278 },
    { name: "Page F", value: 189 },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        padding: "10px",
        marginBottom: "10px",
        boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s ease-in-out",
        cursor: "pointer",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.transform = "scale(1.02)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      <div style={{ padding: "5px", width: "70%" }}>
        <h4 style={{ margin: 0, fontSize: "16px", color: "#333" }}>
          {siteName}
        </h4>
        <div className="mt-2">
          <p>
            <span className="currency-symbol">{amount}</span>
          </p>
          <p
            style={{
              margin: 0,
              fontSize: "10px",
              color: "#555",
              marginTop: "-15px",
            }}
          >
            Average
          </p>
        </div>
        <div style={{ display: "flex", marginTop: "5px" }}>
          <div
            style={{
              flex: 1,
              backgroundColor: "#f1f5f9",
              borderRadius: "4px",
              padding: "8px",
              marginRight: "8px",
              textAlign: "center",
            }}
          >
            <p style={{ margin: 0, fontSize: "10px", color: "#555" }}>High</p>
            <p
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <span className="currency-symbol">143</span>
            </p>
          </div>
          <div
            style={{
              flex: 1,
              backgroundColor: "#f1f5f9",
              borderRadius: "4px",
              padding: "8px",
              textAlign: "center",
            }}
          >
            <p style={{ margin: 0, fontSize: "10px", color: "#555" }}>Low</p>
            <p
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <span className="currency-symbol">52</span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "80px", height: "40px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <Line
              type="monotone"
              dataKey="value"
              stroke="#ffa31a"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EnergyIndex;
