import Dashboard from "./components/Dashboard/Dashboard";
import Site from "./components/site/Site";
import MachineDashboard from "./components/machine/MachineDashboard";
import MachineIndividual from "./components/machine/MachineIndividual";
import Controller from "./components/Controller/Controller";
import Reports from "./components/reports/Reports";
import Digitaltwin from "./components/DigitalTwin/Digitaltwin";
import AllMachines from "./components/machine/AllMachines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faFileAlt,
  faChartPie,
  faGamepad,
  faCogs,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import FuelLevel from "./components/fuel/FuelLevel";
import FuelSummary from "./components/fuel/FuelSummary";
var routes = [
  {
    path: "/dashboard",
    name: "15.0",
    icon: <FontAwesomeIcon icon={faTachometerAlt} />,
    component: <Dashboard />,
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/machine",
    name: "Site Analysis",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <MachineDashboard />,
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/predictive",
    name: "Predictive Analysis",
    icon: <FontAwesomeIcon icon={faChartPie} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <FontAwesomeIcon icon={faBell} />,
    component: "",
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/controller",
    name: "Controller",
    icon: <FontAwesomeIcon icon={faGamepad} />,
    component: <Controller />,
    layout: "/app",
    isDisplay: "1",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    component: <Reports />,
    layout: "/app",
    isDisplay: "1",
  },

  {
    path: "/site/energy/:uuid",
    name: "Site",
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    component: <Site />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/machines/",
    name: "Machine",
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    component: <MachineIndividual />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/machines/:machine_id",
    name: "Machine Analysis",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <MachineIndividual />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/fuel",
    name: "Fuel Level",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <FuelLevel />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/fuelsummary",
    name: "Fuel Summary",
    icon: <FontAwesomeIcon icon={faCogs} />,
    component: <FuelSummary />,
    layout: "/app",
    isDisplay: "0",
  },
  ,
  {
    path: "/site/digitaltwin",
    name: "Digital Twin",
    icon: <FontAwesomeIcon  />,
    component: <Digitaltwin />,
    layout: "/app",
    isDisplay: "0",
  },
  {
    path: "/site/sitemachines",
    name: "Machines",
    icon: <FontAwesomeIcon />,
    component: <AllMachines />,
    layout: "/app",
    isDisplay: "0",
  },
];

export default routes;
