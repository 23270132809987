import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const HorizontalBarChart = ({
  labels,
  dataSeries,
  tickColor = "#fff",
  padding = { top: 40, bottom: 40 },
  lable = "",
  barColor = "rgba(255, 163, 26, 1)",
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: lable,
        data: dataSeries,
        backgroundColor: barColor,
        borderColor: barColor,
        borderWidth: 1,
        borderRadius: {
          topLeft: 10, 
          topRight: 10, 
          bottomLeft: 10, 
          bottomRight: 10, 
        },
        hoverBackgroundColor: barColor, 
        hoverBorderColor: barColor,
      },
    ],
  };

  const options = {
    indexAxis: "y", // Make the chart horizontal
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top", // Position of the legend
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: "transparent",
        },
        ticks: {
          color: tickColor, // Tick color
        },
      },
      y: {
        grid: {
          color: "transparent", // Make grid lines transparent
        },
        ticks: {
          color: tickColor, // Tick color
        },
        barThickness: 15,
        maxBarThickness: 20,
      },
    },
    layout: {
      padding: padding,
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
