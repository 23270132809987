import { useState, useEffect } from "react";
import fuelService from "../../services/fuel/fuelService";
import tokenHelpers from "../../helpers/token.helpers";
import LineChart from "../../views/LineChart";
import Loader from "../loader/Loader";
import PaginationTable from "../Table/Pagintiontable";
import RightPanel from "./fuelRightPanel";

const FuelLevel = () => {
  const [machines, setMachines] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [summary, setSummary] = useState(false);

  const [formData, setFormData] = useState({
    machine: "",
    date: "",
    fromtime: "",
    totime: "",
  });

  useEffect(() => {
    const fetchFuelLevel = async () => {
      try {
        setLoading(true);
        const response = await fuelService.fuelLevel();
        if (Array.isArray(response)) {
          setMachines(response);
          tokenHelpers.setFuel(JSON.stringify(response));
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchFuelLevel();
  }, []);

  const timeStampFormat = (date) => {
    const datetime = new Date(date);
    return datetime.toLocaleString();
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        formData.machine &&
        formData.date &&
        formData.fromtime &&
        formData.totime
      ) {
        const response = await fuelService.fuelHistory(formData);
        setFuelData(response?.fueldata);
        setSummary(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getMachineName = () => {
    if (machines.length > 0 && formData.machine) {
      const machine = machines.filter(
        (machine) => machine.diesel_sen_id == formData.machine
      )[0].machine_name;
      return machine;
    }
    return "";
  };

  return (
    <>
      <div className="bgColor">
        {loading && <Loader />}
        <div className="row">
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
            <h4 className="p-2">Fuel Level Report</h4>
            <div className="row">
              <div className="col-12">
                <table className="table table-respnsive table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>S.no</th>
                      <th className="d-flex justify-content-center">Machine</th>
                      <th className="text-center">Machine ID</th>
                      <th className="text-center">Fuel Level(L)</th>
                      <th className="text-center">Recorded Time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {machines.length > 0
                      ? machines.map((machine, index) => (
                          <tr>
                            <td style={{ width: "5%" }}>{index + 1}</td>
                            <td className="d-flex justify-content-center">
                              {machine?.machine_name}
                            </td>
                            <td className="text-center">{machine?.macid}</td>
                            <td
                              className="text-center"
                              style={{ color: "#FFA31A", fontWeight: "bold" }}
                            >
                              {machine?.fuel?.fueldata?.fuellevel.toFixed(3)}
                            </td>
                            <td className="text-center">
                              {timeStampFormat(
                                machine?.fuel?.fueldata?.deviceTimeStamp
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row p-2">
              <div className="col-3">
                <select
                  className="form-control w-100 shadow-none"
                  name="machine"
                  value={formData.machine}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                >
                  <option value="">Please Select</option>
                  {machines.map((machine, index) => (
                    <option key={index} value={machine?.diesel_sen_id}>
                      {machine?.machine_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-3">
                <input
                  type="date"
                  className="form-control shadow-none"
                  name="date"
                  value={formData.date}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
              </div>

              <div className="col-3">
                <input
                  type="time"
                  className="form-control shadow-none"
                  name="fromtime"
                  value={formData.fromtime}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
              </div>

              <div className="col-3">
                <input
                  type="time"
                  className="form-control shadow-none"
                  name="totime"
                  value={formData.totime}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
              </div>
            </div>
            <div className="row p-2">
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="btn-custom"
                  onClick={(e) => {
                    handleClick(e);
                  }}
                >
                  Get History
                </button>
              </div>
            </div>

            {summary ? (
              <div>
                <h4 className="p-2">Fuel Summary Report</h4>
                <div className="row p-2">
                  <div className="col-12">
                    <LineChart
                      dataLable={fuelData
                        .slice(-15)
                        .map((fuel) => fuel.deviceTimeStamp)}
                      dataSeries={fuelData
                        .slice(-15)
                        .map((fuel) => fuel.fuellevel)}
                      Chartfill={true}
                    />
                  </div>
                </div>
                <div className="row p-2">
                  <div className="col-12">
                    <table className="table table-responsive table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="d-flex justify-content-center">
                            Machine
                          </th>
                          <th className="text-center">Recorded Time</th>
                          <th className="text-center">Fuel Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fuelData.length > 0
                          ? fuelData.slice(-15).map((fuel, index) => (
                              <tr key={index}>
                                <td className="d-flex justify-content-center">
                                  {getMachineName()}
                                </td>
                                <td className="text-center">
                                  {timeStampFormat(fuel?.deviceTimeStamp)}
                                </td>
                                <td className="text-center">
                                  {fuel?.fuellevel.toFixed(3)}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <RightPanel />
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelLevel;
