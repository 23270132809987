import { useState, useEffect } from "react";
import runningImage from "../assets/images/running.png";

const MachineCard = ({
  machinename = "",
  current = 0,
  voltage = 0,
  curavg = 0,
  status = 0,
}) => {
  const [statusName, setStatusName] = useState("");
  const [statusColor, setStatusColor] = useState("green");

  useEffect(() => {
    switch (status) {
      case 0:
        setStatusColor("blue");
        setStatusName("Idle");
        break;
      case 1:
        setStatusColor("green");
        setStatusName("Running");
        break;
      case 2:
        setStatusColor("orange");
        setStatusName("Critical");
        break;
      case 3:
        setStatusColor("red");
        setStatusName("Not Running");
        break;
      default:
        setStatusColor("grey");
        setStatusName("Unknown");
        break;
    }
  }, [status]);

  return (
    <div
      className="image-container"
      style={{
        backgroundColor: "#FFFEFB",
        borderRadius: "5px",
        cursor: "pointer",
        padding: "10px",
      }}
    >
      <div className="">
        <span
          style={{
            backgroundColor: statusColor,
            borderRadius: "50%",
            width: "10px",
            height: "10px",
            display: "inline-block",
            marginRight: "5px",
          }}
        ></span>
        {statusName}
      </div>
      <img src={runningImage} style={{width:"45%"}} className="img-fluid" alt={statusName} />
      <div className="overlay-text-all">{parseInt(current)}</div>
      <div className="overlay-text-normal">current</div>
      <div style={{ fontSize: "14px" }}><b>{machinename}</b></div>
      <div className="row">
        <div className="col-6 px-2">V: {parseFloat(voltage).toFixed(3)}</div>
        <div className="col-6 px-2">I: {parseFloat(curavg).toFixed(2)}</div>
      </div>
    </div>
  );
};

export default MachineCard;
