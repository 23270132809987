import React from "react";

const DaywiseData = ({ dateWiseData, actualDay, machineName }) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const daywiseTotal = (day) => {
    return Object.values(dateWiseData[day] || {}).reduce(
      (daySum, data) => daySum + data.totalValue,
      0
    );
  };

  return (
    <>
      {Object.values(dateWiseData[actualDay] || {}).length > 0 && (
        <div>
          <div className="">{actualDay}</div>
          <table className="table table-responsive table-striped table-bordered">
            <thead>
              <tr className="text-center">
                <th
                  colSpan={9}
                  style={{ backgroundColor: "#193330", color: "white" }}
                >
                  {actualDay}
                </th>
              </tr>
              <tr>
                <th>Date</th>
                <th>Day</th>
                <th>Machine</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {daysOfWeek.map(
                (day) =>
                  day === actualDay &&
                  Object.entries(dateWiseData[day] || {}).map(
                    ([date, data]) => (
                      <tr key={date}>
                        <td>{date}</td>
                        <td>{actualDay}</td>
                        <td>{machineName}</td>
                        <td>{data.totalValue || 0}</td>
                      </tr>
                    )
                  )
              )}
              <tr>
                <td>Grand Total</td>
                <td></td>
                <td></td>
                <td className="d-flex justify-content-end" colSpan="3">
                  {daywiseTotal(actualDay)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DaywiseData;
