import React, { useEffect, useState } from "react";
import controllerService from "../services/Controller/controller.service";

const AddScehdule = ({ onClose, controllerDetails }) => {
  const [errors, setErros] = useState([]);
  const [formData, setFormData] = useState({
    controller_id: controllerDetails.macid,
    schedule_time: "",
    schedule_type: "",
  });
  const [isOpen, setIsOpen] = useState(true);
  const [type, setType] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnchange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErorrs = validate(formData);
    if (Object.keys(formErorrs).length === 0) {
      if (type === "edit") {
        await controllerService.schedule(
          formData.controller_id,
          formData
        );
      } else {
        await controllerService.addschedule(formData);
      }

      onClose();
    } else {
      setErros(formErorrs);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.schedule_type) {
      errors.schedule_type = "Type is required.";
    }
    if (!values.schedule_time) {
      errors.schedule_time = "Time is required.";
    }
    return errors;
  };

  const handleOnload = async () => {
    try {
      const response = await controllerService.getschedule(
        formData.controller_id
      );
      if (response) {
        setFormData({
          ...formData,
          schedule_time: response.schedule_time,
          schedule_type: response.schedule_type,
        });
        setType("edit");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleOnload();
  }, []);
  return (
    <>
      {isOpen && (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Maintenance</h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control-sm w-100"
                      name="schedule_type"
                      value={formData.schedule_type}
                      onChange={(e) => {
                        handleOnchange(e);
                      }}
                    >
                      <option value="">Select Type</option>
                      <option value="Once">Once</option>
                      <option value="Hourly">Hourly</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </select>
                  </div>
                  <p className="text-danger">{errors.schedule_type}</p>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div className="form-group">
                    <label>Time</label>
                    <input
                      type="time"
                      className="form-control shadow-none"
                      name="schedule_time"
                      value={formData.schedule_time}
                      onChange={(e) => {
                        handleOnchange(e);
                      }}
                    />
                  </div>
                  <p className="text-danger">{errors.schedule_time}</p>
                </div>
              </div>
              <button type="submit" className="btn-custom-green">
                Save
              </button>
              &nbsp;
              <button
                type="button"
                className="btn-custom-green"
                onClick={handleClose}
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddScehdule;
