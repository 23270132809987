import React, { useState } from "react";

import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import routes from "../routes";
import { Routes, Route } from "react-router-dom";
import "./MainLayout.scss";
import { useTheme } from "../components/Theme/ThemeContext";

const MainLayout = () => {

  const { darkTheme } = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`layout ${sidebarOpen ? "sidebar-open" : ""}`}>
      <header className="header">
        <Header toggleSidebar={toggleSidebar} />
      </header>
      <div className="main">
        <aside className={`${darkTheme ? "sidebar" : "lite-sidebar"} ${sidebarOpen ? "open" : ""}`}>
          <Sidebar routes={routes} />
        </aside>
        <main className="content">
          <Routes>
            {routes.map((prop, key) => (
              <Route
                path={prop.path}
                element={prop.component}
                key={key}
                exact
              />
            ))}
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
