import api from "../interceptors/axios.interceptor";
const getClienteatils =async (id) => {
  try {
    const response =await api.get(`clients/cid/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export default { getClienteatils };
