import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MachineImage from "../../assets/images/MachineImage.png";
import MachineCard from "../../views/MachineCard";
import tokenHelpers from "../../helpers/token.helpers";
import machineService from "../../services/machine/machineService";
import DonutChart from "../../views/DonutChart";
import Loader from "../loader/Loader";

const AllMachines = () => {
  const navigate = useNavigate();
  const user = JSON.parse(tokenHelpers.getUser());
  const siteId = tokenHelpers.getSiteId();
  const [machines, setMachines] = useState([]);
  const [realtimeData, setRealtimeData] = useState([]);
  const [loading, setLoading] = useState(false);

  var [runningmachines, setrunningMachines] = useState(0);
  var [nrunningmachines, setnrunningMachines] = useState(0);
  var [idlemachines, setidleMachines] = useState(0);
  var [criticalmachines, setcriticalMachines] = useState(0);

  const getMachineList = async () => {
    try {
      setLoading(true);
      const response = await machineService.getMachinebySite(
        user.clientId,
        siteId
      );
      setMachines(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getRealtimeData = async () => {
    try {
      setLoading(true);
      if (machines.length > 0) {
        var realdata = [];
        await Promise.all(
          machines.map(async (item) => {
            const response = await machineService.getRealtimesummary(item.id);
            realdata.push(response);
            // setRealtimeData(response);
          })
        );
        if (realdata.length > 0) {
          setRealtimeData(realdata);
        }
      }
      setLoading(false);
    } catch (err) {
      console.error("Error in getRealtimeData:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMachineList();
  }, []);

  useEffect(() => {
    // const interval = setInterval(() => {
    getRealtimeData();
    // }, 30000);

    // Clear interval on component unmount
    // return () => clearInterval(interval);
  }, [machines]);

  useEffect(() => {
    setLoading(true);
    let runningCount = 0;
    let nRunningCount = 0;
    let idleCount = 0;
    let criticalCount = 0;

    if (realtimeData.length > 0) {
      realtimeData.map(async (item) => {
        var actualStatus;
        if (item[0]) {
          actualStatus = item[0]?.status;
        } else if (item.length == 0) {
          actualStatus = 3;
        }
        if (actualStatus === 1) runningCount++;
        if (actualStatus === 0) idleCount++;
        if (actualStatus === 2) criticalCount++;
        if (actualStatus === 3) nRunningCount++;
      });

      setrunningMachines(runningCount);
      setnrunningMachines(nRunningCount);
      setidleMachines(idleCount);
      setcriticalMachines(criticalCount);
    }
    setLoading(false);
  }, [realtimeData]);

  const getMachineName = (mid) => {
    if (machines.length > 0) {
      const machine = machines.filter((machine) => machine.id == mid);
      if (machine[0]) {
        return machine[0].machine_name;
      }
    }
  };

  const hadleMachineDeatils = (machineID) => {
    navigate(`/app/site/machines/${machineID}`);
  };

  return (
    <>
      <div className="bgColor">
        {loading && <Loader />}
        <div className="row">
          {/* Left column for the image */}
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
            <div className="text-center">
              <img
                src={MachineImage}
                className="img-fluid"
                style={{
                  maxHeight: "50vh",
                  maxWidth: "100%",
                  borderRadius: "5px",
                }}
                alt="Machine Image"
              />
            </div>
            <div className="row mt-3">
              {realtimeData.length > 0 &&
                realtimeData.map((machine, index) => (
                  <div
                    key={index}
                    className="col-4 text-center p-1"
                    onClick={() => {
                      hadleMachineDeatils(machine[0]?.machineid);
                    }}
                  >
                    <MachineCard
                      machinename={getMachineName(machine[0]?.machineid)}
                      status={machine[0]?.status}
                      current={machine[0]?.data.curavg}
                      voltage={machine[0]?.data.voltavg}
                      curavg={machine[0]?.data.curavg}
                    />
                  </div>
                ))}
            </div>
          </div>

          {/* Right column for other content */}
          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            {/* First section */}
            <div className="bg-grey fixed-right">
              <div className="p-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                    Machine Analysis
                  </div>
                </div>

                <div className="row p-4">
                  <div className="col-12 h-100">
                    {runningmachines != 0 && (
                      <DonutChart
                        dataLable={[
                          "Running",
                          "Not Running",
                          "Idle",
                          "Critical",
                        ]}
                        dataSeries={[
                          runningmachines,
                          nrunningmachines,
                          idlemachines,
                          criticalmachines,
                        ]}
                        width={"240px"}
                        mainData={false}
                        color={["#FFD655", "#7B7B7B", "#FFA31A", "#F1F5F8"]}
                        lable={"machines"}
                        legendDisplay={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Your content here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllMachines;
