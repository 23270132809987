// atoms.js
import { atom } from "jotai";

export const siteName = atom("");
export const atomSum = atom(0);
export const atomUtility = atom(0);
export const unitDifference = atom(0);

export const selectedSiteID = atom("");

export const siteList = atom([]);
