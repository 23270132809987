import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

const PaginationTable = ({ data, navigateColumn = {}, title = [] }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate indexes for slicing the data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (event) => {
    setCurrentPage(event.page + 1); // event.page is zero-indexed
  };

  const handleNavigate = (id) => {
    navigate(`${navigateColumn.link}${id}`);
  };

  return (
    <div>
      <table className="table table-responsive table-bordered table-striped">
        {title.length > 0 && (
          <thead>
            <tr>
              {title.map((item) => (
                <td>{item}</td>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {currentItems.length > 0
            ? currentItems.map((machine, index) => (
                <tr key={machine.id}>
                  <td style={{ width: "5%" }}>
                    {indexOfFirstItem + index + 1}
                  </td>
                  <td className="d-flex ">{machine.machine_name}</td>
                  <td className="text-center">{`U Name Plate-${machine.macid}`}</td>
                  {navigateColumn && (
                    <td className="text-center" style={{ color: "#179A00" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleNavigate(machine.id)}
                      >
                        {navigateColumn.text}
                      </span>
                    </td>
                  )}
                </tr>
              ))
            : null}
        </tbody>
      </table>

      <div className="pagination d-flex justify-content-center">
        <Paginator
          first={(currentPage - 1) * itemsPerPage}
          rows={itemsPerPage}
          totalRecords={data.length}
          onPageChange={handlePageChange}
          className="paginator"
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        />
      </div>
    </div>
  );
};

export default PaginationTable;
