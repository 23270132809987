import loader from "../../assets/images/loader.gif";
import "./loader.scss";
const Loader = () => {
  return (
    <>
      <div className="loading-container">
        <div className="loading-content">
          {/* <img src={loader} width="50%" /> */}
          Loading...
        </div>
      </div>
    </>
  );
};

export default Loader;
