const CirleMap = () => {
  return (
    <>
      <div className="mainDiv">
        <div className="circle circle1">110 kWh</div>
        <div className="circle circle2">110 kWh</div>
        <div className="circle circle3">110 kWh</div>
        <div className="circle circle4">110 kWh</div>
      </div>
    </>
  );
};

export default CirleMap;
