import axios from "axios";
import tokenHelpers from "../../helpers/token.helpers";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 10000,
});

const siteID = tokenHelpers.getSiteId() || "";

api.interceptors.request.use(
  (config) => {
    const token = tokenHelpers.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token} ${siteID}`;
    }
    if (config.data && config.data instanceof FormData) {
      delete config.headers["Content-Type"]; // Axios will automatically set it to multipart/form-data
    } else {
      // For JSON or other data types, set Content-Type to application/json
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      console.log("Error status", error.response.status);
      console.log("Error data", error.response.data);

      // Redirect to sign-in page on 401 Unauthorized
      if (error.response.status === 401) {
        window.location.href = "/";
      }
    } else if (error.request) {
      console.log("No response received");
    } else {
      console.log("Error message", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
