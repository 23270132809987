import React, { useEffect, useState } from "react";
import maintenanceService from "../services/maintenance/maintenance.service";
import moment from "moment";

const CalendarPopup = ({
  show,
  onClose,
  slotInfo,
  machineTypeID,
  machine_id,
  load,
}) => {
  const [machineType, setMachineType] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const handleTaskList = async (id) => {
    try {
      const response = await maintenanceService.getbyMaintenancebyType(id);
      setMachineType(response);
    } catch (err) {
      console.error("Failed to fetch maintenance tasks:", err);
      // alert("Failed to fetch tasks. Please try again later.");
    }
  };

  useEffect(() => {
    handleTaskList(machineTypeID);
  }, [machineTypeID]);

  const handleCheckboxChange = (id) => {
    setSelectedTasks((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((taskId) => taskId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      if (selectedTasks.length > 0) {
        const selctedData = selectedTasks.map((item) => ({
          machineId: machine_id,
          taskID: item,
          maintenance_date: slotInfo.start,
        }));

        await maintenanceService.add(selctedData);

        console.log(selctedData, "formdata");

        setSelectedTasks([]);
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
    load();
  };

  if (!show) return null;

  return (
    <div className="row" style={styles.popupOverlay}>
      <div style={styles.popupContent} className="col-4">
        <table className="table">
          <thead>
            <tr>
              <th className="text-left">Task Name</th>
              <th className="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {machineType.length > 0 &&
              machineType.map((item) => (
                <tr key={item?.id}>
                  <td>{item?.taskName}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedTasks.includes(item?.id)}
                      onChange={() => handleCheckboxChange(item?.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <button
          className="mt-3 btn-custom-green"
          onClick={(e) => {
            handleSave(e);
          }}
        >
          Save
        </button>
        &nbsp;
        <button className="mt-3 ml-3 btn-custom-green" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const styles = {
  popupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    overflowY: "auto",
  },
  popupContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    textAlign: "center",
  },
};

export default CalendarPopup;
