import moment from "moment";
const chartData = (data, type) => {
  try {
    if (data) {
      const response = {};
      var xvalue = [];
      var yvalue = [];
      if (type == "hourly") {
        xvalue = data.map((item) => item.createat);
        yvalue = data.map((item) => {
          return parseFloat(item.data.kwh_units).toFixed(3);
        });
        // console.log(response);
      } else if (type == "daily") {
        xvalue = data.map((item) => item.createat);
        yvalue = data.map((item) => {
          return parseFloat(item.data.kwh_units).toFixed(3);
        });
      } else if (type == "monthly") {
        xvalue = data.map((item) => item.createat);
        yvalue = data.map((item) => {
          return parseFloat(item.data.kwh_units).toFixed(3);
        });
      }
      response.x = xvalue;
      response.y = yvalue;
      return response;
    }
  } catch (err) {
    throw err;
  }
};

const formatDate = (dateString, type = "") => {
  // const date = new Date(dateString);
  // const day = String(date.getDate()).padStart(2, "0");
  // const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  // const year = date.getFullYear();

  // const hours = String(date.getHours()).padStart(2, "0");
  // const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = moment(dateString).utc().format("DD-MM-YYYY HH:mm");
  // console.log(formattedDate);
  return formattedDate;
};
export default { chartData, formatDate };
