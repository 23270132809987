import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcase, faChartLine } from "@fortawesome/free-solid-svg-icons";
const Cards = (props) => {
  return (
    <>
      <div
        class="card"
        className={props.bgcolor ? props.bgcolor + " text-white" : "bgColor"}
        style={{ borderRadius: "5px" }}
      >
        <div class="card-body">
          <p class="card-text rounded">
            <div className="row p-2">
              <div className="col-4">
                <FontAwesomeIcon className="font-orange" icon={faSuitcase} />
              </div>

              <div className="col-8">
                <span className="ml-4" style={{ fontSize: "10px" }}>
                  {" "}
                  {props.title}
                </span>
              </div>
            </div>
            <div className="row px-2">
              <div
                className="col-12 font-robot-14"
                style={{ fontWeight: "bold" }}
              >
                {!isNaN(props.units) ? props.units : 0} Kwh
              </div>
            </div>
            <div className="row p-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <span className="currency-symbol font-robot-13">
                      {!isNaN(props.amount) ? props.amount : 0}
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-end font-orange">
                    <FontAwesomeIcon
                      className="font-orange"
                      icon={faChartLine}
                    />
                  </div>
                </div>
              </div>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default Cards;
