import React, { useState, useEffect } from "react";
import { NavLink, useLocation,useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/faradayxWhite.png";
import allRoutes from "../../routes";
import routesSite from "../../routesSite";
import { useTheme } from "../Theme/ThemeContext";

const Sidebar = () => {
  const { darkTheme } = useTheme();
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();
  const handleImageOnclick = () => {
    navigate(`/app/dashboard`);
  };

  useEffect(() => {
    const url = location.pathname.split("/");
    if (url.includes("site") || url.includes("fuel")) {
      setRoutes(routesSite);
    } else {
      setRoutes(allRoutes);
    }
  }, [location]);

  const handleSubMenuToggle = (key) => {
    setOpenSubMenu((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <>
      <div className="mt-3 d-flex align-items-center justify-centent-center">
        <img
          src={logo}
          alt="faradayX"
          // height="30"
          width="80%"
          style={{ cursor: "pointer" }}
          onClick={handleImageOnclick}
        />
      </div>
      <div  style={{ fontSize: "16px",marginTop:"45%" }}>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {routes.map((route, key) => (
            <li key={key} style={{ marginBottom: "10px" }}>
              {route.isDisplay === "1" && (
                <>
                  {route.subRoutes ? (
                    <div
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "block",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSubMenuToggle(key)}
                    >
                      {route.icon && (
                        <span style={{ marginRight: "10px",marginLeft:"30px" }}>
                          {route.icon}
                        </span>
                      )}
                      {route.name}
                      <FontAwesomeIcon
                        icon={openSubMenu[key] ? faChevronDown : faChevronRight}
                        style={{ float: "right" }}
                      />
                    </div>
                  ) : (
                    <NavLink
                      to={route.layout + route.path}
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active" + (!darkTheme ? "-lite" : "")
                          : "nav-link"
                      }
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "block",
                        padding: "10px",
                      }}
                    >
                      {route.icon && (
                        <span style={{ marginRight: "10px",marginLeft:"30px" }}>
                          {route.icon}
                        </span>
                      )}
                      {route.name}
                    </NavLink>
                  )}
                  {route.subRoutes && openSubMenu[key] && (
                    <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
                      {route.subRoutes.map((subRoute, subKey) => (
                        <li key={subKey} style={{ marginBottom: "10px" }}>
                          <NavLink
                            to={subRoute.layout + subRoute.path}
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link active" +
                                  (!darkTheme ? "-lite" : "")
                                : "nav-link"
                            }
                            style={{
                              textDecoration: "none",
                              color: "white",
                              display: "block",
                              padding: "10px",
                            }}
                          >
                            {subRoute.icon && (
                              <span style={{ marginRight: "10px",marginLeft:"30px" }}>
                                {subRoute.icon}
                              </span>
                            )}
                            {subRoute.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
