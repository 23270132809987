import api from "../interceptors/axios.interceptor";

const upload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await api.post("maintenance/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

const add = async (payload) => {
  try {
    const response = await api.post("maintenance", payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const edit = async (uuid, payload) => {
  try {
    const response = await api.put(`maintenance/${uuid}`, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getAll = async () => {
  try {
    const response = await api.get(`maintenance/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getbyUUID = async (uuid) => {
  try {
    const response = await api.get(`maintenance/${uuid}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getbyMachineid = async (id) => {
  try {
    const response = await api.get(`maintenance/machine/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getbyMaintenancebyType = async (id) => {
  try {
    const response = await api.get(`maintenancetask/machinetype/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export default {
  upload,
  add,
  edit,
  getAll,
  getbyUUID,
  getbyMachineid,
  getbyMaintenancebyType,
};
