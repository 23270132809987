import tokenHelpers from "../../helpers/token.helpers";
import api from "../interceptors/axios.interceptor";

const fuelLevel = async () => {
  try {
    const response = await api.get("fuel");
    return response;
  } catch (err) {
    throw err;
  }
};

const fuelHistory = async (payload) => {
  try {
    const date = new Date(payload.date);
    payload.selectdate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    const fuel = JSON.parse(tokenHelpers.getFuel());
    console.log(fuel);
    payload.token = fuel[0]?.fuel?.token;
    const response = await api.post("fuel/history", payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const fuelSummary = async (payload) => {
  try {
    const fromdate = new Date(payload.fromdate);
    const todate = new Date(payload.todate);
    payload.startdate = {
      day: fromdate.getDate(),
      month: fromdate.getMonth() + 1,
      year: fromdate.getFullYear(),
    };
    payload.tdate = {
      day: todate.getDate(),
      month: todate.getMonth() + 1,
      year: todate.getFullYear(),
    };
    const fuel = JSON.parse(tokenHelpers.getFuel());
    console.log(fuel);
    payload.token = fuel[0]?.fuel?.token;
    const response = await api.post("fuel/summary", payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export default { fuelLevel, fuelHistory, fuelSummary };
