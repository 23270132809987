import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({
  dataLable,
  dataSeries,
  width = "400px",
  mainData = true,
  color = [
    "#3F5701",
    "#8F6036",
    "#969A46",
    "#FFA31A",
    "#F44336",
    "#348feb",
    "#eb34ae",
    "#7deb34",
  ],
  lable = "kwh",
  legendDisplay = true,
}) => {
  const data = {
    labels: dataLable,
    datasets: [
      {
        data: dataSeries,
        backgroundColor: color,
        hoverBackgroundColor: color,
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${
              tooltipItem.label
            }: ${tooltipItem.raw.toLocaleString()} ${lable}`;
          },
        },
      },
      legend: {
        display: legendDisplay,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  const sum = dataSeries.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return (
    <div style={{ position: "relative", width: width, height: "400px" }}>
      <Doughnut data={data} options={options} />
      {mainData && (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {sum}
        </div>
      )}
    </div>
  );
};

export default DonutChart;
