import api from "../interceptors/axios.interceptor";

const getAll = async (clientId = 5) => {
  try {
    const response = await api.get(`controller/client/${clientId}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const action = async (payload) => {
  try {
    const response = await api.post(`controller/action`, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const schedule = async (uuid, payload) => {
  try {
    const response = await api.put(`controller/schedule/${uuid}`, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const addschedule = async (payload) => {
  try {
    const response = await api.post(`controller/schedule/`, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getschedule = async (uuid) => {
  try {
    const response = await api.get(`controller/schedule/${uuid}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export default { getAll, action, schedule, getschedule, addschedule };
