import { useState, useEffect } from "react";
import fuelService from "../../services/fuel/fuelService";
import tokenHelpers from "../../helpers/token.helpers";
import LineChart from "../../views/LineChart";
import Loader from "../loader/Loader";
import RightPanel from "./fuelRightPanel";

const FuelSummary = () => {
  const [summary, setSummary] = useState(false);
  const [summaryData, setSummaryData] = useState(true);
  const [machines, setMachines] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);

  const [formData, setFormData] = useState({
    machine: "",
    fromdate: "",
    todate: "",
  });

  useEffect(() => {
    const fetchFuelLevel = async () => {
      try {
        setLoading(true);
        const response = await fuelService.fuelLevel();
        if (Array.isArray(response)) {
          setMachines(response);
          tokenHelpers.setFuel(JSON.stringify(response));
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchFuelLevel();
  }, []);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.machine && formData.fromdate && formData.todate) {
        const response = await fuelService.fuelSummary(formData);
        setSummaryData(response?.summary);
        setSummary(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getMachineName = () => {
    if (machines.length > 0 && formData.machine) {
      const machine = machines.filter(
        (machine) => machine.diesel_sen_id == formData.machine
      )[0].machine_name;
      return machine;
    }
    return "";
  };
  const timeStampFormat = (date) => {
    const datetime = new Date(date);
    return datetime.toLocaleString();
  };
  const consumed = (startlevel, endlevel, refel, thift) => {
    let retval = 0;
    if (
      endlevel != undefined &&
      startlevel != undefined &&
      refel != undefined &&
      thift != undefined
    )
      retval = Math.abs(
        parseFloat(startlevel) +
          parseFloat(refel) -
          parseFloat(thift) -
          parseFloat(endlevel)
      );
    return parseInt(retval + "");
  };


  return (
    <>
      <div className="bgColor">
      {loading && <Loader />}
        <div className="row">
          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
          <h4 className="p-2">Fuel Level Summary</h4>
            <div className="row p-2">
              <div className="col-3">
                <select
                  className="form-control w-100 shadow-none"
                  name="machine"
                  value={formData.machine}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                >
                  <option value="">Please Select</option>
                  {machines.length > 0 &&
                    machines.map((machine, index) => (
                      <option key={index} value={machine?.diesel_sen_id}>
                        {machine?.machine_name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-3">
                <input
                  type="date"
                  className="form-control shadow-none"
                  name="fromdate"
                  value={formData.fromdate}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
              </div>

              <div className="col-3">
                <input
                  type="date"
                  className="form-control shadow-none"
                  name="todate"
                  value={formData.todate}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
              </div>

              <div className="col-3">
                <button
                  className="btn-custom"
                  onClick={(e) => {
                    handleClick(e);
                  }}
                >
                  Get History
                </button>
              </div>
            </div>

            {summary ? (
              <div>
                <div className="row p-2">
                  <div className="col-12"></div>
                </div>
                <div className="row p-2">
                  <div className="col-12">
                    <table className="table table-responsive table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="">Machine</th>
                          <th className="">From Time</th>
                          <th className="">To Time</th>
                          <th className="">Fuel Consumed(L)</th>
                          <th className="">Start Level</th>
                          <th className="">End Level</th>
                          <th className="">Refueled Events</th>
                          <th className="">Refueled Amount(L)</th>
                          <th className="">Theft Events</th>
                          <th className="">Theft Amount(L)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summaryData.length > 0 &&
                          summaryData.map((item) => (
                            <tr>
                              <td>{getMachineName(item.deviceId)}</td>
                              <td>{timeStampFormat(item.startTime)}</td>
                              <td>{timeStampFormat(item.endTime)}</td>
                              <td>
                                {consumed(
                                  item?.startLevel,
                                  item?.endLevel,
                                  item?.refuel_amount,
                                  item?.theft_amount
                                )}
                              </td>
                              <td>{item.startLevel.toFixed(3)}</td>
                              <td>{item.endLevel.toFixed(3)}</td>
                              <td>{item.refuel_count}</td>
                              <td>{item.refuel_amount.toFixed(3)}</td>
                              <td>{item.theft_count}</td>
                              <td>{item.theft_amount.toFixed(3)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <LineChart
                      dataLable={summaryData.map((fuel) => fuel.endTime)}
                      dataSeries={summaryData.map((fuel) =>
                        consumed(
                          fuel?.startLevel,
                          fuel?.endLevel,
                          fuel?.refuel_amount,
                          fuel?.theft_amount
                        )
                      )}
                      Chartfill={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <RightPanel />
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelSummary;
